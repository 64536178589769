<template>
    <div class="popup-wrap" v-if="delete_account_notification">
      <div class="popup">
        <h2>Success!</h2>
        <p>
          Your Bank of America account ending<br>in 4765 has been successfully added.
        </p>
        <p1>
          Would you like to add a nickname for<br>this account? This can be changed later.
        </p1>
        <div class="input">
          <input type="text" placeholder="Type nickname (Optional)" />
        </div>
        <div class="footer"></div>
        <button class="next-btn" @click="delete_account_notification = false">Next</button>
      </div>
    </div>
    <div class="bank-account">
      <div class="account">
        <span class="header">Bank & Credit Card Accounts</span>
      </div>
      <div class="img">
        <img src="@/assets/bank-account.png" alt="" />
        <span
          style="color: var(--base-80, #707070); font-family: Outfit; font-size: 16px; font-style: normal; font-weight: 300; line-height: 130%; margin-top: 15px;">Link
          to your bank account, easily manage your balance</span>
        <button class="instructions-btn" @click="openPlaidLink()">Instructions</button>
      </div>
    </div>
    <div class="add-bank-header">Linked accountsssssss</div>
    <div class="add-bank" @click="delete_account_notification = true">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_11629_331263)">
          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#707070" />
        </g>
        <defs>
          <clipPath id="clip0_11629_331263">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      Add a bank account
    </div>
  </template>
    //lang="https://cdn.plaid.com/link/v2/stable/link-initialize.js"
  <script>
  import { defineComponent } from "vue";
  import { httpsCallable } from "firebase/functions";
  import { functions, auth } from "@/main";
  // import "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
  // import Plaid from 'plaid';
  
  export default defineComponent({
    data() {
      return {
        delete_account_notification: false,
      };
    },
    methods: {
      openPlaidLink() {
        const Plaid = window.Plaid;
        console.log(Plaid)
        const handler = Plaid.create({
          clientName: 'Your App Name',
          env: 'development', // or 'development' or 'production'
          product: ['auth', 'transactions'],
          key: 'link-sandbox-982f46fc-aba3-4ccc-9b4a-d0daa5dac92c', // Your Plaid Public Key
          onSuccess: (publicToken, metadata) => {
            // Handle successful Plaid Link authentication
            console.log("sucesssssssssssssssssss")
            // this.fetchAccessToken(publicToken);
          },
          // Add other Plaid Link configuration options as needed
        });
  
        handler.open(); // Open the Plaid Link dialog
      },
    },
    computed: {
    },
  });
  </script>
    
  <style lang="scss" scoped>
  #green {
    color: #53bd71;
  }
  
  #red {
    color: #da5252;
  }
  
  #bold {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */
  
    text-align: center;
  
    color: #000000;
  }
  
  .account {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 90%;
  }
  
  .header {
    letter-spacing: 0.5px;
    transition: all 0.3s ease-in-out;
  
    font-family: Outfit;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
  
    display: flex;
    align-items: flex-end;
  
    color: var(--base-100, #2B2B2B);
  }
  
  .input {
    transform: translateY(-25px);
    display: flex;
    border-radius: 4px;
    width: 100%;
    height: 45px;
    border: 1px solid var(--base-60, #A0A0A0);
  
    font-size: 16px;
    font-weight: 400;
  }
  
  input[type="text"] {
    all: unset;
    color: var(--base-60, #A0A0A0);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 20.8px */
    letter-spacing: -0.5px;
  
    &::placeholder {
      color: #A0A0A0;
    }
  }
  
  .footer {
    width: 99%;
    transform: translateY(15px);
    border: 0.5px solid var(--base-40, #DDD);
  }
  
  .next-btn {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    transform: translateY(30px);
    padding: 10px;
  
    width: 40%;
    border: none;
    /* Primary400 */
  
    background: #5157bf;
    border-radius: 8px;
  
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* or 18px */
  
    text-align: center;
  
    color: #ffffff;
    cursor: pointer;
  }
  
  .bank-account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 20px;
    align-items: center;
  
    img {
      max-width: 250px;
      margin-top: 75px;
    }
  }
  
  .img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
  }
  
  .instructions-btn {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px;
  
    width: 40%;
    border: none;
    /* Primary400 */
  
    background: var(--base-0, #FFF);
    border: 1px solid var(--primary-400, #5157BF);
    border-radius: 8px;
  
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* or 18px */
  
    text-align: center;
    color: #3D43A4;
    cursor: pointer;
  }
  
  .add-bank-header {
    display: flex;
    padding: 20px 85px;
  
  
    color: var(--base-100, #2B2B2B);
  
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 23.4px */
    letter-spacing: 0.5px;
  }
  
  .add-bank {
    display: flex;
    padding: 50px 60px;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--base-20, #F3F3F3);
    width: 25%;
    padding: 45px;
    margin-left: 85px;
  
    color: var(--base-80, #707070);
    font-weight: 300;
  
    cursor: pointer;
  }
  
  .popup-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.66);
  }
  
  .popup {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    padding: 50px 40px;
  
    p {
      font-family: "Outfit";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 18px */
  
      transform: translateY(-45px);
      display: flex;
      align-items: center;
      text-align: center;
  
      color: var(--base-100, #2B2B2B);
    }
  
    p1 {
      font-family: "Outfit";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 18px */
  
      transform: translateY(-50px);
      display: flex;
      align-items: center;
      text-align: center;
  
      color: var(--base-100, #2B2B2B);
    }
  
    h2 {
      transform: translateY(-30px);
      font-family: "Outfit";
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 130%;
      /* or 21px */
  
      display: flex;
      align-items: center;
      text-align: center;
  
      color: var(--base-100, #2B2B2B);
    }
  
    svg {
      transform: translateX(-8px);
    }
  
  }
  </style>
    