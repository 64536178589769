<template>
  <div class="col-3">
    <div class="snippet" data-title="dot-typing">
      <div class="stage">
        <div class="dot-typing"></div>
      </div>
    </div>
  </div>
</template>

<style>
/* Your CSS code here */

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E9E9E9;
  margin-left: 8px;
  width: 4rem;
  height: 2.5rem;
  padding-left: 1rem;
  border-radius: 15px;
}

/* ==============================================
 * Dot Pulse
 * ============================================== */
 .dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #BBBBBB, 9999px 0 0 0 #BBBBBB, 10014px 0 0 0 #BBBBBB;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 
      9974px 0 0 0 #BBBBBB, 
      9989px 0 0 0 #BBBBBB, 
      10004px 0 0 0 #BBBBBB;
  }
  16.667% {
    box-shadow: 
      9974px -10px 0 0 #8E8E8E, 
      9989px 0 0 0 #BBBBBB, 
      10004px 0 0 0 #BBBBBB;
  }
  33.333% {
    box-shadow: 
      9974px 0 0 0 #8E8E8E, 
      9989px 0 0 0 #8E8E8E, 
      10004px 0 0 0 #BBBBBB;
  }
  50% {
    box-shadow: 
      9974px 0 0 0 #8E8E8E, 
      9989px -10px 0 0 #8E8E8E, 
      10004px 0 0 0 #BBBBBB;
  }
  66.667% {
    box-shadow: 
      9974px 0 0 0 #BBBBBB, 
      9989px 0 0 0 #BBBBBB, 
      10004px 0 0 0 #8E8E8E;
  }
  83.333% {
    box-shadow: 
      9974px 0 0 0 #BBBBBB, 
      9989px 0 0 0 #BBBBBB, 
      10004px -10px 0 0 #8E8E8E;
  }
  100% {
    box-shadow: 
      9974px 0 0 0 #BBBBBB, 
      9989px 0 0 0 #BBBBBB, 
      10004px 0 0 0 #8E8E8E;
  }
}

</style>
