export const cityStateArray = [
    "New York, NY",
    "Los Angeles, CA",
    "Chicago, IL",
    "Houston, TX",
    "Phoenix, AZ",
    "Philadelphia, PA",
    "San Antonio, TX",
    "San Diego, CA",
    "Dallas, TX",
    "San Jose, CA",
    "Austin, TX",
    "Jacksonville, FL",
    "Fort Worth, TX",
    "Columbus, OH",
    "Charlotte, NC",
    "San Francisco, CA",
    "Indianapolis, IN",
    "Seattle, WA",
    "Denver, CO",
    "Washington, DC",
    "Boston, MA",
    "El Paso, TX",
    "Nashville, TN",
    "Detroit, MI",
    "Oklahoma City, OK",
    "Portland, OR",
    "Las Vegas, NV",
    "Memphis, TN",
    "Louisville, KY",
    "Baltimore, MD",
    "Milwaukee, WI",
    "Albuquerque, NM",
    "Tucson, AZ",
    "Fresno, CA",
    "Mesa, AZ",
    "Sacramento, CA",
    "Atlanta, GA",
    "Kansas City, MO",
    "Colorado Springs, CO",
    "Miami, FL",
    "Raleigh, NC",
    "Omaha, NE",
    "Long Beach, CA",
    "Virginia Beach, VA",
    "Oakland, CA",
    "Minneapolis, MN",
    "Tulsa, OK",
    "Arlington, TX",
    "Tampa, FL",
    "New Orleans, LA",
    "Wichita, KS",
    "Cleveland, OH",
    "Bakersfield, CA",
    "Aurora, CO",
    "Anaheim, CA",
    "Honolulu, HI",
    "Santa Ana, CA",
    "Riverside, CA",
    "Corpus Christi, TX",
    "Lexington, KY",
    "Henderson, NV",
    "Stockton, CA",
    "Saint Paul, MN",
    "Cincinnati, OH",
    "St. Louis, MO",
    "Pittsburgh, PA",
    "Greensboro, NC",
    "Lincoln, NE",
    "Anchorage, AK",
    "Plano, TX",
    "Orlando, FL",
    "Irvine, CA",
    "Newark, NJ",
    "Durham, NC",
    "Chula Vista, CA",
    "Toledo, OH",
    "Fort Wayne, IN",
    "St. Petersburg, FL",
    "Laredo, TX",
    "Jersey City, NJ",
    "Chandler, AZ",
    "Madison, WI",
    "Lubbock, TX",
    "Scottsdale, AZ",
    "Reno, NV",
    "Buffalo, NY",
    "Gilbert, AZ",
    "Glendale, AZ",
    "North Las Vegas, NV",
    "Winston-Salem, NC",
    "Chesapeake, VA",
    "Norfolk, VA",
    "Fremont, CA",
    "Garland, TX",
    "Irving, TX",
    "Hialeah, FL",
    "Richmond, VA",
    "Boise, ID",
    "Spokane, WA",
    "Baton Rouge, LA",
    "Tacoma, WA",
    "San Bernardino, CA",
    "Modesto, CA",
    "Fontana, CA",
    "Des Moines, IA",
    "Moreno Valley, CA",
    "Santa Clarita, CA",
    "Fayetteville, NC",
    "Birmingham, AL",
    "Oxnard, CA",
    "Rochester, NY",
    "Port St. Lucie, FL",
    "Grand Rapids, MI",
    "Huntsville, AL",
    "Salt Lake City, UT",
    "Frisco, TX",
    "Yonkers, NY",
    "Amarillo, TX",
    "Glendale, CA",
    "Huntington Beach, CA",
    "McKinney, TX",
    "Montgomery, AL",
    "Augusta, GA",
    "Aurora, IL",
    "Akron, OH",
    "Little Rock, AR",
    "Tempe, AZ",
    "Columbus, GA",
    "Overland Park, KS",
    "Grand Prairie, TX",
    "Tallahassee, FL",
    "Cape Coral, FL",
    "Mobile, AL",
    "Knoxville, TN",
    "Shreveport, LA",
    "Worcester, MA",
    "Ontario, CA",
    "Vancouver, WA",
    "Sioux Falls, SD",
    "Chattanooga, TN",
    "Brownsville, TX",
    "Fort Lauderdale, FL",
    "Providence, RI",
    "Newport News, VA",
    "Rancho Cucamonga, CA",
    "Santa Rosa, CA",
    "Peoria, AZ",
    "Oceanside, CA",
    "Elk Grove, CA",
    "Salem, OR",
    "Pembroke Pines, FL",
    "Eugene, OR",
    "Garden Grove, CA",
    "Cary, NC",
    "Fort Collins, CO",
    "Corona, CA",
    "Springfield, MO",
    "Jackson, MS",
    "Alexandria, VA",
    "Hayward, CA",
    "Clarksville, TN",
    "Lakewood, CO",
    "Lancaster, CA",
    "Salinas, CA",
    "Palmdale, CA",
    "Hollywood, FL",
    "Springfield, MA",
    "Macon, GA",
    "Kansas City, KS",
    "Sunnyvale, CA",
    "Pomona, CA",
    "Killeen, TX",
    "Escondido, CA",
    "Pasadena, TX",
    "Naperville, IL",
    "Bellevue, WA",
    "Joliet, IL",
    "Murfreesboro, TN",
    "Midland, TX",
    "Rockford, IL",
    "Paterson, NJ",
    "Savannah, GA",
    "Bridgeport, CT",
    "Torrance, CA",
    "McAllen, TX",
    "Syracuse, NY",
    "Surprise, AZ",
    "Denton, TX",
    "Roseville, CA",
    "Thornton, CO",
    "Miramar, FL",
    "Pasadena, CA",
    "Mesquite, TX",
    "Olathe, KS",
    "Dayton, OH",
    "Carrollton, TX",
    "Waco, TX",
    "Orange, CA",
    "Fullerton, CA",
    "Charleston, SC",
    "West Valley City, UT",
    "Visalia, CA",
    "Hampton, VA",
    "Gainesville, FL",
    "Warren, MI",
    "Coral Springs, FL",
    "Cedar Rapids, IA",
    "Round Rock, TX",
    "Sterling Heights, MI",
    "Kent, WA",
    "Columbia, SC",
    "Santa Clara, CA",
    "New Haven, CT",
    "Stamford, CT",
    "Concord, CA",
    "Elizabeth, NJ",
    "Athens, GA",
    "Thousand Oaks, CA",
    "Lafayette, LA",
    "Simi Valley, CA",
    "Topeka, KS",
    "Norman, OK",
    "Fargo, ND",
    "Wilmington, NC",
    "Abilene, TX",
    "Odessa, TX",
    "Columbia, MO",
    "Pearland, TX",
    "Victorville, CA",
    "Hartford, CT",
    "Vallejo, CA",
    "Allentown, PA",
    "Berkeley, CA",
    "Richardson, TX",
    "Arvada, CO",
    "Ann Arbor, MI",
    "Rochester, MN",
    "Cambridge, MA",
    "Sugar Land, TX",
    "Lansing, MI",
    "Evansville, IN",
    "College Station, TX",
    "Fairfield, CA",
    "Clearwater, FL",
    "Beaumont, TX",
    "Independence, MO",
    "Provo, UT",
    "West Jordan, UT",
    "Murfreesboro, TN",
    "Palm Bay, FL",
    "El Monte, CA",
    "Carlsbad, CA",
    "North Charleston, SC",
    "Temecula, CA",
    "Clovis, CA",
    "Springfield, IL",
    "Meridian, ID",
    "Westminster, CO",
    "Costa Mesa, CA",
    "High Point, NC",
    "Manchester, NH",
    "Pueblo, CO",
    "Lakeland, FL",
    "Pompano Beach, FL",
    "West Palm Beach, FL",
    "Antioch, CA",
    "Everett, WA",
    "Downey, CA",
    "Lowell, MA",
    "Centennial, CO",
    "Elgin, IL",
    "Richmond, CA",
    "Peoria, IL",
    "Broken Arrow, OK",
    "Miami Gardens, FL",
    "Billings, MT",
    "Jurupa Valley, CA",
    "Sandy Springs, GA",
    "Gresham, OR",
    "Lewisville, TX",
    "Hillsboro, OR",
    "Ventura, CA",
    "Greeley, CO",
    "Inglewood, CA",
    "Waterbury, CT",
    "League City, TX",
    "Santa Maria, CA",
    "Tyler, TX",
    "Davie, FL",
    "Lakewood, NJ",
    "Daly City, CA",
    "Boulder, CO",
    "Allen, TX",
    "West Covina, CA",
    "Sparks, NV",
    "Wichita Falls, TX",
    "Green Bay, WI",
    "San Mateo, CA",
    "Norwalk, CA",
    "Rialto, CA",
    "Las Cruces, NM",
    "Chico, CA",
    "El Cajon, CA",
    "Burbank, CA",
    "South Bend, IN",
    "Renton, WA",
    "Vista, CA",
    "Davenport, IA",
    "Edinburg, TX",
    "Tuscaloosa, AL",
    "Carmel, IN",
    "Spokane Valley, WA",
    "San Angelo, TX",
    "Vacaville, CA",
    "Clinton, MI",
    "Bend, OR",
    "Woodbridge, NJ",
    "Erie, PA",
    "Allen, TX",
    "South Gate, CA",
    "Hesperia, CA",
    "Mission Viejo, CA",
    "Shawnee, KS",
    "Concord, NC",
    "Citrus Heights, CA",
    "Manteca, CA",
    "Fort Myers, FL",
    "Palm Coast, FL",
    "Bryan, TX",
    "Coon Rapids, MN",
    "Mansfield, TX",
    "East Orange, NJ",
    "Waukegan, IL",
    "Camden, NJ",
    "Waterloo, IA",
    "Skokie, IL",
    "Reading, PA",
    "Chino, CA",
    "New Bedford, MA",
    "Redding, CA",
    "Indio, CA",
    "Lawton, OK",
    "Kirkland, WA",
    "St. Joseph, MO",
    "Bethlehem, PA",
    "San Marcos, CA",
    "Champaign, IL",
    "Fort Smith, AR",
    "Bryan, TX",
    "Roswell, GA",
    "Yuma, AZ",
    "Yorba Linda, CA",
    "Orem, UT",
    "Rapid City, SD",
    "Westland, MI",
    "Jackson, TN",
    "Dearborn, MI",
    "Hawthorne, CA",
    "Union City, CA",
    "Harrisburg, PA",
    "Albany, GA",
    "Tustin, CA",
    "Apple Valley, CA",
    "Redwood City, CA",
    "Alhambra, CA",
    "Southfield, MI",
    "Lynwood, CA",
    "Leesburg, VA",
    "Saginaw, MI",
    "Madison, AL",
    "Perris, CA",
    "Middletown, OH",
    "Nampa, ID",
    "Bowie, MD",
    "Kingsport, TN",
    "Lakewood, WA",
    "Upland, CA",
    "Rogers, AR",
    "Evanston, IL",
    "Waukesha, WI",
    "Brockton, MA",
    "Roanoke, VA",
    "Cranston, RI",
    "St. George, UT",
    "Meridian, MS",
    "Longmont, CO",
    "Bloomington, IN",
    "Johns Creek, GA",
    "Newton, MA",
    "Fishers, IN",
    "St. Charles, MO",
    "Plymouth, MN",
    "Meridian, ID",
    "Coon Rapids, MN",
    "Lakewood, OH",
    "Laguna Niguel, CA",
    "Dubuque, IA",
    "Blue Springs, MO",
    "Covina, CA",
    "Redmond, WA",
    "Kendall, FL",
    "Westminster, MD",
    "Council Bluffs, IA",
    "Burlington, VT",
    "San Ramon, CA",
    "Lakeville, MN",
    "Beaverton, OR",
    "Porterville, CA",
    "Ames, IA",
    "Plainfield, NJ",
    "DeSoto, TX",
    "Germantown, MD",
    "Highland, CA",
    "Kokomo, IN",
    "Homestead, FL",
    "Missouri City, TX",
    "St. Cloud, MN",
    "Twin Falls, ID",
    "Petaluma, CA",
    "Madera, CA",
    "Redlands, CA",
    "Decatur, AL",
    "Paramount, CA",
    "Arcadia, CA",
    "Blaine, MN",
    "Casa Grande, AZ",
    "Appleton, WI",
    "Redmond, OR",
    "Lodi, CA",
    "Burien, WA",
    "Chapel Hill, NC",
    "San Clemente, CA",
    "Gadsden, AL",
    "Taylorsville, UT",
    "Maple Grove, MN",
    "Deltona, FL",
    "Gardena, CA",
    "Coeur d'Alene, ID",
    "Wilson, NC",
    "Mount Vernon, NY",
    "Harrisonburg, VA",
    "Manhattan, KS",
    "Burlington, NC",
    "Lewiston, ME",
    "Rockville, MD",
    "Noblesville, IN",
    "Jonesboro, AR",
    "Sammamish, WA",
    "Wellington, FL",
    "Rocklin, CA",
    "North Port, FL",
    "Chapel Hill, NC",
    "Woodbury, MN",
    "Grand Junction, CO",
    "Aliso Viejo, CA",
    "Brentwood, TN",
    "Plainfield, IL",
    "Burnsville, MN",
    "Livermore, CA",
    "Missoula, MT",
    "Rapid City, SD",
    "Lawrence, KS",
    "Iowa City, IA",
    "Kettering, OH",
    "Chicopee, MA",
    "Murray, UT",
    "Schenectady, NY",
    "Troy, NY",
    "Niagara Falls, NY",
    "Saratoga Springs, NY",
    "San Marcos, TX",
    "Hendersonville, TN",
    "Pocatello, ID",
    "La Crosse, WI",
    "West Lafayette, IN",
    "Sunrise, FL",
    "Quincy, MA",
    "Jacksonville, NC",
    "Pasco, WA",
    "Chesterfield, MO",
    "Lincoln, NE",
    "West Jordan, UT",
    "Eau Claire, WI",
    "Sanford, FL",
    "Des Plaines, IL",
    "Watertown, NY",
    "New Rochelle, NY",
    "Muskogee, OK",
    "Janesville, WI",
    "Georgetown, TX",
    "The Colony, TX",
    "Pleasanton, CA",
    "Mishawaka, IN",
    "Baldwin Park, CA",
    "Bayonne, NJ",
    "Waltham, MA",
    "Redmond, WA",
    "San Leandro, CA",
    "Hoffman Estates, IL",
]
