import { CollectionReference, doc, DocumentData, getDocs, collection } from "firebase/firestore";
import { auth, db } from "@/main";

const parseGetTransactions = async () => {
  if (auth.currentUser) {
    console.log(auth.currentUser.uid);
    const userDocRef = doc(db, "Real-Users", auth.currentUser.uid);
    const transactionsCollectionRef = collection(userDocRef, "transactions");
    const transactionsQuerySnapshot = await getDocs(transactionsCollectionRef);

    console.log("transactionsQuerySnapshot: ", transactionsQuerySnapshot);

    if (!transactionsQuerySnapshot.empty) {
      // Access details document
      const transactionData = transactionsQuerySnapshot.docs[0].data();
      if (transactionData && transactionData.account && Array.isArray(transactionData.account)) {
        // Perform some action here if account data is an array
        console.log("Account data is an array.");
      } else {
        console.log("No documents found in the banking collection.");
      }
    }

    const groupedTransactions: { [key: string]: any } = {};

    transactionsQuerySnapshot.forEach((doc) => {
      const transaction = doc.data();
      const { amount, category, name, authorized_date, payment_meta, transaction_id } = transaction;
      const categoryName = category;
      const transactionName = name;

      if (!groupedTransactions[categoryName]) {
        groupedTransactions[categoryName] = {};
      }
      if (!groupedTransactions[categoryName][transactionName]) {
        groupedTransactions[categoryName][transactionName] = { transactions: [] };
      }

      // Check if transaction_id and category already exist in groupedTransactions
      const existingTransaction = groupedTransactions[categoryName][transactionName].transactions.find(
        (t: any) => t.transaction_id === transaction_id && t.category === categoryName
      );

      if (!existingTransaction) {
        groupedTransactions[categoryName][transactionName].transactions.push({ amount, category: categoryName, name: transactionName, authorized_date, payment_meta, transaction_id });
      }
    });
    
    return groupedTransactions;
  }
};

export default parseGetTransactions;
