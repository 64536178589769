import defaultImage from '../assets/account.svg'
import { createStore } from 'vuex';

export type State = {
  display_auto_budget_setup: boolean;
  display_add_transaction_modal: boolean;
  display_edit_transaction_modal: boolean;
  display_edit_add_category_modal: boolean;
  display_uncategorized_modal: boolean;
  clicked_category_name: string | null;
  display_edit_expense_modal: boolean;
  display_edit_expense_category_modal: boolean;
  display_create_category_modal: boolean;
  display_create_category_final_modal: boolean;
  display_add_budget_modal: boolean;
  display_edit_budget_modal: boolean;
  display_first_time_modal: boolean;
  display_all_budgets_modal: boolean;
  display_create_rule_modal: boolean;
  display_edit_rule_modal: boolean;
  display_rules_popup_modal: boolean;
  display_monetization_modal: boolean;
  display_payment_modal: boolean;
  display_successful_modal: boolean;
  selected_package: string;
  came_from_edit_expense_modal: boolean;
  selected_budget_id: string;
  selected_transaction_name: string;
  selected_transaction_object: Transaction;
  selected_budget: Budget;
  bank_access_token: string;
  category_mode: 'edit' | 'create' | null;
  selected_item: Item;
  viewing_inividual_category_trends: { state: boolean, name: string }

  selected_category: Category;
  category_to_edit: { name: string, icon: string, average: string, category_type: string, expense_type: string, total_amount: number, id: string };
  selected_category_id: string;

  budgets: Budget[];
  items: Item[];
  categories: Category[]
  rules: Rule[]
  selectedRule: Rule;

  selected_payment_method: "";
  userDetails: any;
  user: {
    birthday: string;
    gender: string;
    race: string;
    first_name: string,
    last_name: string,
    onboarded: boolean,
  };

  onboarded: boolean;
  tooltips_completed: boolean;
  isLoading: boolean;
  profile_photo: any;
  expenseDetails: any;
  deleteExpenseDetails: DeleteExpenseDetails;
  holdingAllExpenseDetails: HoldingAllExpenseDetails,
};

export type DeleteExpenseDetails = {
  user_id: string
  length: number;
  categories: { [key: string]: any[] }; 
  push(element: any): void;
  [index: number]: any;
};

export type HoldingAllExpenseDetails = {
  expenses: { [key: string]: { category: string, type: string, name: string, amount: number } };
  length: number;
  push(category: string, type: string, name: string, amount: number): void;
};

export type Rule = {
  id: string;
  name: string;
  thenStatements: {
      action: string;
      value: string;
  }[];
  ifStatements: {
      boolean: string | null;
      parameter: string;
      matchingMethod: string;
      value: string | number;
  }[];
};

export type Budget = {
  id: string;
  name: string;
  amount: number;
  repeat: boolean;
  start_date: any;
  end_date: any;
  created_at: any;
  favorite_categories: string[];
  categories: Category[];
  averageExpenses?: number
};
export type Item = {
  id: string;
  name: string;
  amount: number;
  created_at: any;

  items: Item[];
};
export type Category = {
  id: string;
  category_type: string;
  expense_type: string;
  displayName: string;
  name: string;
  amount: number;
  created_at: any;
  color: string

  transactions: Transaction[];
};
export type Transaction = {
  authorized_date: any;
  id: string;
  name: string;
  amount: number;
  budget_id: number;
  notes: string;
  payment_type: string;
  date: any;
  category: string; //local variable, does not exist in the database
};

export const store = createStore({
  state: {
    budget_id: null,
    expenseDetails: {},
    deleteExpenseDetails: {
      user_id: "", // Assuming this is your user ID field
      length: 0,   // Initial length
      categories: {} as { [key: string]: number[] },  // Object with category names as keys and arrays of numbers as values
  
      // Custom push method to add transaction IDs to a category
      push(category: string, transactionId: number) {
          if (!this.categories[category]) {
              this.categories[category] = [];
          }
          this.categories[category].push(transactionId);
          this.length++; // Increment length after adding a transaction
      },
    },
    holdingAllExpenseDetails: {
      length: 0,
      expenses: {} as { [key: string]: { category: string; type: string; name: string; amount: number } },
      push(category: string, type: string, name: string, amount: number) {
        if (!this.expenses[category]) {
          this.expenses[category] = { category, type, name, amount };
        } else {
          // Handle existing category case if needed
        }
        this.length++;
      },
    },
    display_auto_budget_setup: false,
    display_add_transaction_modal: false,
    display_edit_transaction_modal: false,
    display_edit_add_category_modal: false,
    display_edit_expense_modal: false,
    display_edit_expense_category_modal: false,
    display_uncategorized_modal: false,
    clicked_category_name: null,
    display_create_category_modal: false,
    display_create_category_final_modal: false,
    display_add_budget_modal: false,
    display_edit_budget_modal: false,
    display_create_rule_modal: false,
    display_rules_popup_modal: false,
    display_edit_rule_modal: false,
    display_first_time_modal: false,
    display_all_budgets_modal: false,
    display_monetization_modal: false,
    display_payment_modal: false,
    display_successful_modal: false,
    selected_package: null,
    bank_access_token: null,
    selected_budget_id: null,
    came_from_edit_expense_modal: false,
    budgets: [] as Budget[],
    categories: [] as Category[],
    selected_budget: {} as Budget,
    selected_category: { name: "Choose a category" } as Category,
    category_to_edit: null,
    category_mode: null,
    viewing_individual_category_trends: { state: false, name: '' },
    rules: [] as Rule[],
    selectedRule: null,
    selected_payment_method: "",
    userDetail: null,
    user: {
      birthday: "",
      gender: "",
      race: "",
      first_name: "",
      last_name: "",
      onboarded: false,
    },
    onboarded: false,
    tooltips_completed: false,
    isLoading: false,
    profile_photo: defaultImage,
    setBanksDetail: {},
    selected_transaction_name: null,
    selected_transaction_object: null,
    selectedTransactionName: '',
  },
  getters: {
    getExpenseDetails(state) {
      return state.expenseDetails;
    },
    getUserDetails(state){
      return state.userDetail;
    },
    getUser(state){
      return state.user;
    },
    loading_status(state){
      return state.isLoading;
    },
    getOnboardedStatus(state){
      return state.onboarded;
    },
    get_budgets(state){
      return state.budgets;
    },
    get_rules(state) {
      return state.rules
    },
    get_category(state){
      return state.categories;
    },
    getBanksDetail(state){
      return state.setBanksDetail;
    },
    get_selected_budget(state){
      return state.selected_budget;
    },
    get_selected_category(state){
      return state.selected_category;
    },
    get_profile_photo(state){
      return state.profile_photo
    },
    get_viewing_individual_category_trends(state){
      return state.viewing_individual_category_trends
    },
    get_selected_package(state){
      return state.selected_package
    },
    get_bank_access_token(state) {
      return state.bank_access_token
    },
    get_selected_budgetId(state) {
      return state.selected_budget_id;
    },
  },
  mutations: {
    addToHoldingAllExpenseDetails(state, payload) {
      state.holdingAllExpenseDetails.push(payload.category, payload.type, payload.name, payload.amount);
    },
    saveExpenseDetails(state, expenseDetails) {
      state.expenseDetails = expenseDetails;
    },
    setClickedCategoryName(state, value) {
      state.clicked_category_name = value;
    },
    setSelectedTransactionObject(state, transactionObj){
      state.selected_transaction_object = transactionObj
    },
    setSelectedTransactionName(state, transactionName) {
      state.selected_transaction_name = transactionName;
    },
    setBudgetId(state, budget_id){
      state.budget_id = budget_id;
    },
    setUser(state, value) {
      state.user = value;
    },
    setUserNames(state, payload) {
      state.user.first_name = payload.first_name;
      state.user.last_name = payload.last_name;
    },
    setBanksDetail(state, banksDetail){
      state.setBanksDetail = banksDetail;
    },
    setOnboardedStatus(state, onboarded){
      state.onboarded = onboarded;
    },
    setUserDetails(state, details){
      state.userDetail = details;
    },
    set_profile_photo(state, photo){
      state.profile_photo = photo;
    },
    set_loading(state, isLoading){
      state.isLoading = isLoading;
    },
    set_tooltips_completed_status(state, isComplete){
      state.tooltips_completed = isComplete;
    },
    set_selected_package(state, pkg){
      state.selected_package = pkg;
    },
    set_selected_budgetId(state, budgetId){
      state.selected_budget_id = budgetId
    },
    setSelectedRule(state, rule){
      state.selectedRule = rule;
    },
    set_bank_access_token(state, bank_access_token){
      state.bank_access_token = bank_access_token
    },
    toggle_create_rule_modal(state) {
      state.display_create_rule_modal = !state.display_create_rule_modal;
    },
    toggle_rules_popup_modal(state) {
      state.display_rules_popup_modal = !state.display_rules_popup_modal;
    },
    toggle_edit_rule_modal(state) {
      state.display_edit_rule_modal = !state.display_edit_rule_modal;
    },
    toggle_edit_expense_modal(state) {
      state.display_uncategorized_modal = false;
      state.display_edit_expense_modal = !state.display_edit_expense_modal;
    },
    toggle_edit_expense_category_modal(state) {
      state.display_edit_expense_category_modal = !state.display_edit_expense_category_modal;
    },
    toggle_uncategorized_modal(state, categoryName) {
      state.display_uncategorized_modal = !state.display_uncategorized_modal
      if(state.clicked_category_name === null) {
         state.clicked_category_name = categoryName
      }
    },
    toggle_auto_budget_setup(state) {
      state.display_auto_budget_setup = !state.display_auto_budget_setup;
    },
    toggle_add_transaction_modal(state) {
      state.display_add_transaction_modal =
        !state.display_add_transaction_modal;
    },
    toggle_edit_transaction_modal(state){
      if (state.display_edit_expense_modal) {
          state.came_from_edit_expense_modal = true;
          state.display_edit_expense_modal = false;
          console.log("came from edit_exepense", state.came_from_edit_expense_modal)
      }
      
      state.display_edit_transaction_modal = !state.display_edit_transaction_modal;
    },
    toggle_monetization_modal(state){
      state.display_monetization_modal = !state.display_monetization_modal;
    },
    toggle_payment_modal(state){
      state.display_payment_modal = !state.display_payment_modal
    },
    toggle_successful_modal(state){
      state.display_successful_modal = !state.display_successful_modal
    },
    toggle_create_category_modal(state){
      state.display_create_category_modal = !state.display_create_category_modal;
    },
    toggle_create_category_final_modal(state){
      state.display_create_category_final_modal = !state.display_create_category_final_modal;
    },
    toggle_edit_add_category_modal(state){
      state.display_edit_add_category_modal = !state.display_edit_add_category_modal;
    },
    toggle_add_budget_modal(state) {
      state.display_add_budget_modal = !state.display_add_budget_modal;
    },
    toggle_edit_budget_modal(state) {
      state.display_edit_budget_modal = !state.display_edit_budget_modal;
    },
    toggle_first_time_modal(state) {
     state.onboarded = true;
    },
    toggle_all_budgets_modal(state) {
      state.display_add_budget_modal = false;
      state.display_all_budgets_modal = !state.display_all_budgets_modal;
    },
    add_rules(state, rule: Rule) {
      state.rules = [...state.rules, rule]
    },
    add_budget(state, budget: Budget) {
      state.budgets = [...state.budgets, budget];
    },

    remove_budget(state, budget_id: string) {
      state.budgets = state.budgets.filter((budget) => {
        return budget.id !== budget_id;
      });
    },

    update_budget(state, budget: Budget) {
      const index = state.budgets.findIndex((b) => b.id === budget.id);
      if (index >= 0) {
        state.budgets[index] = budget;
      }
    },

    select_budget(state, budget_index: number) {
      state.selected_budget = state.budgets[budget_index];
      state.selected_category = { name: "Choose a category" } as Category;
    },

    select_category_mode(state, mode){
      state.category_mode = mode;
    },
    setAverageExpenses(state, averageExpenses: number) {
      state.selected_budget.averageExpenses = averageExpenses;
    },
    add_category(state, params: { budget_id: string; category: Category }) {
      state.budgets.forEach((budget) => {
        if (budget.id === params.budget_id) {
          budget.categories
            ? [budget.categories.push(params.category)]
            : [(budget.categories = [params.category])];
        }
      });
    },
    update_category(state, params: { budget_id: string, updatedCategory: any }){
      state.budgets.forEach((budget) => {
        if (budget.id === params.budget_id) {
          const newBudgetCategories = budget.categories.map(cat => {
            return cat.id === params.updatedCategory.id ? {...cat, ...params.updatedCategory} : cat
          })
          console.log(newBudgetCategories)
          budget.categories = newBudgetCategories
        }
      });
    },

    select_category(state, category: Category) {
      state.selected_category = category
    },

    select_category_to_edit(state, category){
      state.category_to_edit = category
    },

    add_favorite_category(state, params: { budget_id: string; category_id: string }) {
      state.budgets.forEach((budget) => {
        if (budget.id === params.budget_id) {
          budget.favorite_categories
            ? [budget.favorite_categories.push(params.category_id)]
            : [(budget.favorite_categories = [params.category_id])];
        }
      });
    },

    remove_category(state, params: { budget_id: string; category_id: string }) {
      state.budgets.forEach((budget) => {
        if (budget.id === params.budget_id) {
          budget.categories = budget.categories.filter((category) => {
            return category.id !== params.category_id;
          });
        }
      });
    },

    remove_favorite_category(state, params: { budget_id: string, category_id: string }) {
      state.budgets.forEach((budget) => {
        if (budget.id === params.budget_id) {
          budget.favorite_categories = budget.favorite_categories.filter((category) => {
            return category !== params.category_id 
          })
        }
      })
    },

    add_transaction(
      state,
      params: {
        budget_id: string;
        category_id: string;
        transaction: Transaction;
      }
    ) {
      state.budgets.forEach((budget) => {
        if (budget.id === params.budget_id) {
          budget.categories.forEach((category) => {
            if (category.id === params.category_id) {
              category.transactions
                ? [category.transactions.push(params.transaction)]
                : [(category.transactions = [params.transaction])];
            }
          });
        }
      });
    },

    remove_transaction(
      state,
      params: { budget_id: string; category_id: string; transaction_id: string }
    ) {
      state.budgets.forEach((budget) => {
        if (budget.id === params.budget_id) {
          budget.categories.forEach((category) => {
            if (category.id === params.category_id) {
              category.transactions = category.transactions.filter(
                (transaction) => {
                  return transaction.id !== params.transaction_id;
                }
              );
            }
          });
        }
      });
    },

    set_payment_method(state, payment_method: string) {
      state.selected_payment_method = payment_method as any;
    },
    set_viewing_individual_category_trends(state, obj){
      state.viewing_individual_category_trends = obj
    },
    update_budget_name(state, budgetName) {
      state.selected_budget.name = budgetName;
    },
    addCategories(state, categories) {
      state.categories.push(...categories);
    }
  },

  actions: {
    deleteExpenseDetails(context: any, categoryName: string) {
      context.commit('addToDeleteExpenseDetails', categoryName);
      console.log('deleteExpenseDetails:', context.state.deleteExpenseDetails);
    },
    addToDeleteExpenseDetails(context: any, { categoryName, transactionId }: { categoryName: string, transactionId: number }) {
      context.state.deleteExpenseDetails.push(categoryName, transactionId);
    },
    holdingAllExpenseDetails(context: any, { category, type, name, amount }: { category: string, type: string, name: string, amount: number }) {
      context.commit('addToHoldingAllExpenseDetails', { category, type, name, amount });
      console.log('holdingAllExpenseDetails:', context.state.holdingAllExpenseDetails);
    },
    addToHoldingAllExpenseDetails(context: any, { category, type, name, amount }: { category: string, type: string, name: string, amount: number }) {
      context.state.deleteExpenseDetails.push({ category, type, name, amount });
    },
    updateUser({ commit }, payload) {
      commit('setUserNames', payload);
    },
    saveExpenseDetails({ commit }, expenseDetails) {
      commit('saveExpenseDetails', expenseDetails);
    },
    saveDeleteExpenseDetails({commit}, deleteExpenseDetails) {
      commit('deleteExpenseDetails', deleteExpenseDetails)
    },
    saveHoldingAllExpenseDetails({commit}, holdingAllExpenseDetails) {
      commit('holdingAllExpenseDetails', holdingAllExpenseDetails)
    },
    setSelectedTransactionName({ commit }, selected_transaction_name) {
      commit('setSelectedTransactionName', selected_transaction_name);
    },
    setBudgetId({commit}, budget_id) {
      commit('budget_id', budget_id);
    },
    setSelectedTransactionObject({ commit }, selected_transaction_object) {
      commit('setSelectedTransactionName', selected_transaction_object);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setBanksDetail({ commit }, banks){
      commit("setBanksDetail", banks);
    },
    setUserDetails({ commit }, details){
      commit("setUserDetails", details);
    },
    setOnboardedStatus({ commit }, onboarded){
      commit("setOnboardedStatus", onboarded);
    },
    setSelectedRule({commit}, rule){
     commit("setSelectedRule", rule)
    },
    setSelectedPackage({commit}, pkg){
      commit("setSelectedPackage", pkg)
    },
    setBankAcessToken({ commit }, bankToken){
      commit("setBankAcessToken", bankToken)
    },
    toggle_create_rule_modal({commit}){
      commit("toggle_create_rule_modal")
    },
    toggle_rules_popup_modal({ commit }) {
      commit("toggle_rules_popup_modal")
    },
    toggle_edit_rule_modal({commit}) {
      commit("toggle_edit_rule_modal")
    },
    toggle_edit_expense_modal({commit}){
      commit("toggle_edit_expense_modal")
    },
    toggle_edit_expense_category_modal({ commit }){
      commit("toggle_edit_expense_category_modal")
    },
    toggle_create_category_modal({ commit }) {
      commit("toggle_create_category_modal");
    },
    toggle_create_category_final_modal({ commit }) {
      commit("toggle_create_category_final_modal");
    },
    toggle_uncategorized_modal({commit}, categoryName){
      commit("toggle_uncategorized_modal", categoryName)
    },
    toggle_auto_budget_setup({commit}){
      commit("toggle_auto_budget_setup");
    },
    toggle_add_transaction_modal({ commit }) {
      commit("toggle_add_transaction_modal");
    },
    toggle_edit_transaction_modal({ commit }) {
      commit("toggle_edit_transaction_modal");
    },
    toggle_monetization_modal({ commit }){
      commit("toggle_monetization_modal")
    },
    toggle_payment_modal({ commit }){
      commit("toggle_payment_modal")
    },
    toggle_successful_modal ({ commit }) {
      commit("toggle_successful_modal")
    },
    toggle_edit_add_category_modal({ commit }) {
      commit("toggle_edit_add_category_modal");
    },
    toggle_add_budget_modal({ commit }) {
      commit("toggle_add_budget_modal");
    },
    toggle_edit_budget_modal({ commit }) {
      commit("toggle_edit_budget_modal");
    },
    toggle_first_time_modal({ commit }) {
      commit("toggle_first_time_modal");
    },
    toggle_all_budgets_modal({ commit }) {
      commit("toggle_all_budgets_modal");
    },
    add_rules({commit}, rule: Rule) {
      commit("add_rules", rule)
    },
    add_budget({ commit }, budget: Budget) {
      commit("add_budget", budget);
    },
    add_category(
      { commit },
      params: { budget_id: number; category: Category }
    ) {
      commit("add_category", params);
    },
    update_category(
      { commit },
      params: { budget_id: number; updatedCategory: any }
    ) {
      commit("update_category", params);
    },
    select_category({ commit }, category_id: string) {
      commit("select_category", category_id);
    },
    select_category_to_edit({ commit }, category){
      commit("select_category_to_edit", category)
    },
    select_category_mode({ commit }, mode){
      commit('select_category_mode', mode)
    },
    add_transaction(
      { commit },
      params: {
        budget_id: number;
        category_id: number;
        transaction: Transaction;
      }
    ) {
      commit("add_transaction", params);
    },

    remove_budget({ commit }, budget_id: number) {
      commit("remove_budget", budget_id);
    },

    select_budget({ commit }, budget_id) {
      commit("select_budget", budget_id);
    },
    remove_category(
      { commit },
      params: { budget_id: number; category_id: number }
    ) {
      commit("remove_category", params);
    },
    add_favorite_category(
      { commit },
      params: { budget_id: string, category_id: string }
    ) {
      commit("add_favorite_category", params);
    },
    remove_favorite_category(
      { commit },
      params: { budget_id: string, category_id: string }
    ) {
      commit("remove_favorite_category", params);
    },
    remove_transaction(
      { commit },
      params: { budget_id: number; category_id: number; transaction_id: number }
    ) {
      commit("remove_transaction", params);
    },
    set_payment_method({ commit }, payment_method: string) {
      commit("set_payment_method", payment_method);
    },
    setBudgetName({ commit }, budgetName) {
      commit('update_budget_name', budgetName);
    },
  },
  modules: {},
});
