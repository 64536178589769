<template>
  <div class="rules-popup-wrapper">
    <div class="rules-popup-container">
      <div class="rules-popup-header">
        <div class="header-left">
          <i
            class="pi pi-arrow-left create-category-arrow"
            @click="$store.dispatch('toggle_edit_expense_modal')"
          ></i>
          <div style="margin-left: 1rem; font-size: 1.1rem; font-weight: 500">
            Rules
          </div>
        </div>
        <i
          class="pi pi-times"
          @click="$store.dispatch('toggle_rules_popup_modal')"
        ></i>
      </div>
      <div class="rules-subheader-container">
        <div class="search-rule">
          <div class="search-rule-content">
            <div class="search-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.1475 14.3525L12.4025 11.6075C13.3963 10.43 14 8.9105 14 7.25C14 3.5225 10.9775 0.5 7.25 0.5C3.5225 0.5 0.5 3.5225 0.5 7.25C0.5 10.9775 3.5225 14 7.25 14C8.91125 14 10.43 13.397 11.606 12.4025L14.351 15.1475C14.4613 15.257 14.606 15.3125 14.7485 15.3125C14.891 15.3125 15.0372 15.2578 15.146 15.1475C15.3672 14.9278 15.3673 14.5722 15.1475 14.3525ZM1.625 7.25C1.625 4.14875 4.14875 1.625 7.25 1.625C10.3512 1.625 12.875 4.14875 12.875 7.25C12.875 10.3512 10.3512 12.875 7.25 12.875C4.14875 12.875 1.625 10.3512 1.625 7.25Z"
                  fill="#707070"
                />
              </svg>
            </div>
            <input
              type="text"
              v-model="searchTerm"
              placeholder="Search rules"
              class="search-bar"
            />
          </div>
        </div>
        <button
          @click="$store.dispatch('toggle_create_rule_modal')"
          class="new-rule-button"
        >
          + New Rule
        </button>
      </div>
      <div class="rules-content-container">
        <div
          v-for="(rule, index) in filteredRules"
          :key="index"
          class="individual-table-row"
        >
          <div class="rule-left-side-content">
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.5 2C4.5 2.53043 4.28929 3.03914 3.91421 3.41421C3.53914 3.78929 3.03043 4 2.5 4C1.96957 4 1.46086 3.78929 1.08579 3.41421C0.710714 3.03914 0.5 2.53043 0.5 2C0.5 1.46957 0.710714 0.960859 1.08579 0.585786C1.46086 0.210714 1.96957 0 2.5 0C3.03043 0 3.53914 0.210714 3.91421 0.585786C4.28929 0.960859 4.5 1.46957 4.5 2ZM2.5 11C3.03043 11 3.53914 10.7893 3.91421 10.4142C4.28929 10.0391 4.5 9.53043 4.5 9C4.5 8.46957 4.28929 7.96086 3.91421 7.58579C3.53914 7.21071 3.03043 7 2.5 7C1.96957 7 1.46086 7.21071 1.08579 7.58579C0.710714 7.96086 0.5 8.46957 0.5 9C0.5 9.53043 0.710714 10.0391 1.08579 10.4142C1.46086 10.7893 1.96957 11 2.5 11ZM2.5 18C3.03043 18 3.53914 17.7893 3.91421 17.4142C4.28929 17.0391 4.5 16.5304 4.5 16C4.5 15.4696 4.28929 14.9609 3.91421 14.5858C3.53914 14.2107 3.03043 14 2.5 14C1.96957 14 1.46086 14.2107 1.08579 14.5858C0.710714 14.9609 0.5 15.4696 0.5 16C0.5 16.5304 0.710714 17.0391 1.08579 17.4142C1.46086 17.7893 1.96957 18 2.5 18ZM13.5 2C13.5 2.53043 13.2893 3.03914 12.9142 3.41421C12.5391 3.78929 12.0304 4 11.5 4C10.9696 4 10.4609 3.78929 10.0858 3.41421C9.71071 3.03914 9.5 2.53043 9.5 2C9.5 1.46957 9.71071 0.960859 10.0858 0.585786C10.4609 0.210714 10.9696 0 11.5 0C12.0304 0 12.5391 0.210714 12.9142 0.585786C13.2893 0.960859 13.5 1.46957 13.5 2ZM11.5 11C12.0304 11 12.5391 10.7893 12.9142 10.4142C13.2893 10.0391 13.5 9.53043 13.5 9C13.5 8.46957 13.2893 7.96086 12.9142 7.58579C12.5391 7.21071 12.0304 7 11.5 7C10.9696 7 10.4609 7.21071 10.0858 7.58579C9.71071 7.96086 9.5 8.46957 9.5 9C9.5 9.53043 9.71071 10.0391 10.0858 10.4142C10.4609 10.7893 10.9696 11 11.5 11ZM11.5 18C12.0304 18 12.5391 17.7893 12.9142 17.4142C13.2893 17.0391 13.5 16.5304 13.5 16C13.5 15.4696 13.2893 14.9609 12.9142 14.5858C12.5391 14.2107 12.0304 14 11.5 14C10.9696 14 10.4609 14.2107 10.0858 14.5858C9.71071 14.9609 9.5 15.4696 9.5 16C9.5 16.5304 9.71071 17.0391 10.0858 17.4142C10.4609 17.7893 10.9696 18 11.5 18Z"
                fill="#2B2B2B"
              />
            </svg>
            <div class="rule-name">{{ rule.name }}</div>
          </div>
          <div
            class="table-icon-container arrow-icon"
            @click="arrowClick(rule)"
            style="cursor: pointer"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
                fill="#2B2B2B"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/main";
import { defineComponent } from "vue";
import { store } from "@/store";
import { mapActions } from "vuex";
import { getRules } from "@/services/RulesApi";

export default defineComponent({
  name: "RulesPopup",
  data() {
    return {
      searchTerm: "",
    };
  },  
 beforeMount() {
    this.$store.state.display_edit_expense_modal = false;
    this.$store.state.create_rule_modal = false;
    this.$store.state.display_edit_rule_modal = false;
  },
  methods: {
    ...mapActions(['setSelectedRule']),
    arrowClick(rule){
      console.log(rule)
      this.$store.dispatch("toggle_edit_rule_modal")
      this.setSelectedRule(rule)
      console.log("IN STATE", this.$store.state.selectedRule)
    },
  },
  computed: {
    filteredRules() {
      const searchTerm = this.searchTerm.toLowerCase();
      const innerArray = store.state.rules[0];
      if (!innerArray || !Array.isArray(innerArray)) {
        return []; 
      }
      return innerArray.filter((rule) => {
        return rule.name.toLowerCase().includes(searchTerm);
      });
    },
  },
  async created(){
    if (store.state.rules.length === 0) {
      await getRules();
    }
  }
});
</script>

<style lang="scss" scoped>
.rules-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
}

.rules-popup-container {
  position: absolute;
  top: 10%;
  background-color: white;
  border-radius: 8px;
  width: 45%;
  padding: 20px;
}

.rules-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d1d0;
  padding-bottom: 4px;
}

.header-left {
  display: flex;
  align-items: center;
}

// .search-rule {
//   display: flex;
//   justify-content: right;
//   margin-top: 1.5rem;
// }

.search-rule-content {
  display: flex;
  align-items: center;
  background-color: #f2f2f3;
  border-radius: 15px;
}

.search-bar {
  padding-left: 10px;
  border: none;
}

.search-bar:focus {
  outline: none;
}

.search-icon {
  padding: 5px;
  padding-top: 7px;
  padding-left: 10px;
  width: 2vw;
}

.rules-subheader-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.new-rule-button {
  color: #5056bf;
  font-weight: 500;
}

.rules-content-container {
  margin-top: 1rem;
}

.individual-table-row {
  display: flex;
  border-bottom: 1px solid black;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.rule-left-side-content {
  display: flex;
  align-items: center;
}

.rule-name {
  margin-left: 1rem;
}
</style>
