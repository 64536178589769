import axios from "axios";
import { auth } from "@/main";

const getFirebaseTransactions = async (accessToken: string) => {
    const userUid = auth.currentUser && auth.currentUser.uid;

    if (!userUid) {
        throw new Error("User not authenticated");
    }

    const url = 'https://backendprod-uza6jon7lq-uc.a.run.app/auth/get_firebase_transactions';
    const payload = {
        user_id: userUid,
        access_token: accessToken
    };

    try {
        const response = await axios.post(url, payload);
        console.log("Inside getTransactionsFromFirebase",response.data)
        return response.data; // Assuming the endpoint returns the transactions data
    } catch (error) {
        console.error('Error fetching transactions:', error);
        throw error;
    }
};

export {getFirebaseTransactions}
