<template>
    <div>
      <div class="cash-flow-wrapper">
        <div class="header">
          <div class="header-item" style="margin-right: 1rem">&lt;</div>
          <div class="header-item" style="margin-right: 0.4rem">{{ bankName }}</div>
          <div class="header-item">{{ bankMask }}</div>
        </div>
        <div class="chart-bank">
          <div class="chart-container" style="border: 1px solid black;">
            <div class="chart-buttons">
              <button @click="toggleChart('week')">W</button>
              <button @click="toggleChart('6M')">6M</button>
              <button @click="toggleChart('12M')">12M</button>
            </div>
            <div class="chart-graph" style="width: 50%;">
              <div v-if="loading">Loading...</div>
              <div v-if="selectedChart === 'week' && transactions">
                <canvas ref="chart1" width="200" height="200"></canvas>
              </div>
              <div v-if="selectedChart === '6M' && transactions">
                <canvas ref="chart2" width="400" height="400"></canvas>
              </div>
              <div v-if="selectedChart === '12M' && transactions">
                <canvas ref="chart3" width="400" height="400"></canvas>
              </div>
              <div v-if="error" class="error">{{ error }}</div>
              <canvas ref="chart"></canvas>
            </div>
          </div>
          <div class="bank-statments">
            <!-- Placeholder for bank statements -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import { getFirebaseTransactions } from "@/services/GetTransactions";
  import Chart from 'chart.js/auto';
  
  export default defineComponent({
    name: "CashView",
    data() {
      return {
        transactions: null,
        loading: false,
        error: null,
        selectedChart: "week",
        chartInstances: {
          chart1: null,
          chart2: null,
          chart3: null,
          mainChart: null
        }
      };
    },
    props: ["bankName", "bankMask"],
    computed: {
      accessToken() {
        return this.$store.state.bank_access_token;
      },
    },
    methods: {
      async fetchTransactions() {
        this.loading = true;
        try {
          const transactions = await getFirebaseTransactions(this.accessToken);
          this.transactions = transactions;
          console.log("Transactions fetched:", this.transactions);
          // this.renderCharts();
        } catch (error) {
          console.error("Error fetching transactions:", error);
          this.error = "Failed to fetch transactions.";
        } finally {
          this.loading = false;
        }
      },
      renderCharts() {
        if (!this.transactions) return;
  
        this.clearCharts();
  
        if (this.selectedChart === "week") {
          this.renderChartWeek();
        } else if (this.selectedChart === "6M") {
          this.renderChart6Months();
        } else if (this.selectedChart === "12M") {
          this.renderChart12Months();
        }
      },
      renderChartWeek() {
        const labels = ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"];
        const incomes = [200, 200, 200, 200, 200];
        const expenses = [200, 200, 200, 200, 200];
  
        this.renderBarChart("chart1", labels, incomes, expenses);
      },
      renderBarChart(refName, labels, incomes, expenses) {
        const ctx = this.$refs[refName].getContext("2d");
        const options = {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "x",
          plugins: {
            legend: {
              position: "right",
            },
            title: {
              display: true,
              text: "Transactions",
            },
          },
        };
  
        const datasets = [
          {
            label: "Income",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            data: incomes,
          },
          {
            label: "Expenses",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
            data: expenses,
          },
        ];
  
        this.chartInstances[refName] = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: options,
        });
      },
      clearCharts() {
        Object.keys(this.chartInstances).forEach(key => {
          const chart = this.chartInstances[key];
          if (chart) {
            chart.destroy();
            this.chartInstances[key] = null;
          }
        });
      },
      toggleChart(chartType) {
        this.selectedChart = chartType;
        this.renderCharts();
      },
    },
    mounted() {
      this.fetchTransactions();
    },
  });
  </script>
  

  
  <style scoped>
  .cash-flow-wrapper {
    padding: 12px;
    margin: auto;
    margin-top: 1rem;
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .header {
    display: flex;
  }
  </style>
  