<template>
    <div class="spinner-container" v-if="loading">
        <div class="spinner"> </div>
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: 'loadingPage',
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    }
})

</script>
<style scoped>
        .spinner {
            width: 50px;
            height: 50px;
            border: 5px solid rgba(0, 0, 0, 0.1);
            border-top: 5px solid #3498db;
            border-radius: 50%;
            animation: spin 1s linear infinite;
            margin: 100px auto;
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

        .spinner-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
        }
</style>