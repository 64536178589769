import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LandingView from "../views/LandingView.vue";
import LoginView from "../views/LoginView.vue";
import RegisterView from "../views/RegisterView.vue";
import EmailView from "../views/EmailView.vue";
import ProfileView from "../views/ProfileView.vue";
import BankAccountView from "../views/BankView.vue";
import GoogleSheetsView from "../views/SheetsView.vue";
import NotificationsView from "../views/NotificationsView.vue";
import SecurityView from "../views/SecurityView.vue";
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue";
import TermsOfServiceView from "../views/TermsView.vue";
import AutomaticTransactions from "../views/AutomaticTransactions.vue";
import NameYourBudgetView from "../views/NameYourBudgetView.vue";
import BudgetView from "../views/BudgetView.vue";
import CashView from "../views/CashFlow.vue"
import BankView from "../views/BankView.vue"
import { getAuth } from "firebase/auth";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingView,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginView,
    
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterView,
    
  },
  {
    path: "/email",
    name: "EmailPage",
    component: EmailView,
    
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/budget",
    name: "BudgetView",
    component: () => import("../views/BudgetView.vue"),
  },
  {
    path: "/AutomaticTransactions",
    name: "AutomaticTransactions",
    component: AutomaticTransactions,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfileView,
    meta: { requiresAuth: true },
  },
  {
    path: "/bank",
    name: "BankAccountView",
    component: BankAccountView,
  },
  {
    path: "/bank/:bankName",
    name: "CashFlowView",
    component: CashView,
  },
  {
    path: "/sheets",
    name: "GoogleSheetsView",
    component: GoogleSheetsView,
  },
  {
    path: "/notifications",
    name: "NotificationsView",
    component: NotificationsView,
  },
  {
    path: "/security",
    name: "SecurityView",
    component: SecurityView,
  },
  {
    path: "/transactions",
    name: "TransactionsView",
    component: () => import("../views/TransactionsView.vue"),
  },
  {
    path: "/trends",
    name: "TrendsView",
    component: () => import("../views/TrendsView.vue"),
  },
  {
    path: "/goals",
    name: "GoalsView",
    component: () => import("../views/GoalsView.vue"),
  },
  {
    path: "/community",
    name: "CommunityView",
    component: () => import("../views/CommunityView.vue"),
  },
  {
    path:"/rules",
    name:"RuleView",
    component: () => import("../views/RuleView.vue")
  },
  {
    path: "/home/faqs",
    name: "FAQs",
    component: LandingView,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: LandingView,
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyView,
  },
  {
    path: "/terms",
    name: "TermsOfService",
    component: TermsOfServiceView,
  },
  {
    path: '/budgetView',
    name: 'BudgetView',
    component: BudgetView,
  },
  {
    path: '/NameYourBudget',
    name: 'NameYourBudgetView',
    component: NameYourBudgetView,
  },
  {
    path: '/BankView',
    name: 'BankView',
    component: () => import("../views/BankView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = getAuth().currentUser;

  if (requiresAuth && !isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;
