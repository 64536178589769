<template>
  <div class="overlay">
    <div class="popup">
      <i
        class="pi pi-times"
        @click="onClose"
      ></i>
      <div class="header-message">
        <h2>Delete keyword item?</h2>
      </div>
      <div class="message">
        Are you sure you want to delete this keyword <br />
        item? Transactions with this name will not be <br />
        automatically categorized in your budget.
      </div>
      <div></div>
      <div class="buttons-container">
        <button class="button cancel" @click="onClose">Cancel</button>
        <button class="button delete" @click="confirmDelete">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["close", "confirmDelete"],
  methods: {
    onClose() {
      this.$emit("close");
    },
    confirmDelete() {
      this.$emit("confirmDelete");
    },
  },
};
</script>

<style scoped>
/* Add your scoped CSS styles here */
</style>
<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  padding-top: 40px;
  align-items: top;
  z-index: 999;
  font-family: 'Outfit';
}

.popup {
  position: relative;
  background: white;
  text-align: center;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 33%; /* Increase the height */
}

.header-message {
  display: flex;
  justify-content: start;
  width: 100%;
  padding-bottom: 13px;
}

.message {
  font-size: 1.3rem;
  margin-bottom: 20px;
  color: black;
  text-align: start
}

.pi {
  width: 100%;
  display: flex;
  justify-content: end;
}

.buttons-container {
  display: flex;
  justify-content: end;
  width: 100%;
}

.button {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 30%;
}

.button.delete {
  background: red;
  color: white !important;
}

.button.cancel {
  margin-right: 15px;
  background: gray;
  color: black;
}
</style>
