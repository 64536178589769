// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import { MotionPlugin } from '@vueuse/motion'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import vuetify from './plugins/vuetify';
// import { VDatePicker } from 'vuetify/labs/VDatePicker';
import { loadFonts } from './plugins/webfontloader'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC0F_GxNme-SSLMdmqozdBi4scqtwNjn1g",
  authDomain: "doughrisebackend.firebaseapp.com",
  projectId: "doughrisebackend",
  storageBucket: "doughrisebackend.appspot.com",
  messagingSenderId: "658155491232",
  appId: "1:658155491232:web:99f0fe6cc3d8f2b6ef9ec5",
  measurementId: "G-DJT6SPZP5F",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'login', { method: 'email' });
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);
const storage = getStorage(app)

let vue_app: any;

onAuthStateChanged(auth, () => {
  if (!vue_app) {
    vue_app = createApp(App).use(store).use(MotionPlugin).use(router).use(vuetify).mount("#app");
  }
  if (auth.currentUser) {
    vue_app.getUserData(true);
  }
});

loadFonts()

export { app, db, analytics, functions, auth, storage, updateProfile, ref, uploadBytes, getDownloadURL };
