<template>
  <div class="content">
    <v-img max-width="1000" height="600" style=" align-self: flex-start; display: flex; transform: translateY(150px);" src="../assets/login-image.png"></v-img>
    <div class="login-box noselect">
      <div class="login-wrap">
        <v-img width="55" height="55" style="border-radius: 9px;" src="../assets/NewLogoLight.png"></v-img>
          <v-img width="151" height="27" style="transform: translateY(5px); margin-bottom: 1rem;" src="../assets/budetwise.png"></v-img>
        
        <div class="header">
          <h5>Budget <span>your everything</span></h5>
        </div>

        <!-- external login providers -->
        <div style="display: flex; flex-direction: column; width: 100%; gap: 9px; justify-content: space-evenly; align-items: center;" v-if="!resetPage">

          <!-- Google login -->
          <button class="login-button" @click="signInWithGoogle()" style="display: flex; width: 100%; height: 4rem; margin-top: 20px; cursor: pointer; border: 1px solid #000000; border-radius: 10px; font-family: 'Outfit'; font-style: normal; font-weight: 600; font-size: 16px;  color: #2b2b2b;">
      <v-icon style="transform: translateX(-0px); width: 35px; height: 35px;">
        <svg style=""
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                  <path
                    fill="#4285F4"
                    d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                  />
                  <path
                    fill="#34A853"
                    d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                  />
                  <path
                    fill="#EA4335"
                    d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                  />
                </g>
              </svg>
      </v-icon>
            Continue with Google
          </button>
    <!-- Google login -->
    
    <!-- Facebook login -->
    <button class="login-button" @click="signInWithFacebook()" style="display: flex; width: 100%; height: 4rem; cursor: pointer; border: 1px solid #000000; border-radius: 10px; font-family: 'Outfit'; font-style: normal; font-weight: 600; font-size: 16px;  color: #2b2b2b;">
      <v-icon style="transform: translateX(-0px); width: 35px; height: 35px;">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.38825 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9165 4.6875 14.6576 4.6875C15.9705 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.3399 7.875 13.875 8.79998 13.875 9.74897V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
                  fill="#1877F2"
                />
                <path
                  d="M16.6711 15.4688L17.2031 12H13.875V9.74897C13.875 8.79998 14.3399 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9165 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C10.7359 23.9501 11.3621 24 12 24C12.6379 24 13.264 23.9501 13.875 23.8542V15.4688H16.6711Z"
                  fill="white"
                />
              </svg>
      </v-icon>
            Continue with Facebook
          </button>
    <!-- Facebook login -->

    <!-- Apple login -->
    <button class="login-button" @click="signInWithFacebook()" style="display: flex; width: 100%; height: 4rem; cursor: pointer; border: 1px solid #000000; border-radius: 10px; font-family: 'Outfit'; font-style: normal; font-weight: 600; font-size: 16px;  color: #2b2b2b;">
      <v-icon style="transform: translateX(-0px); margin-right: 0px; width: 35px; height: 35px;">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19.6349 20.3601L19.6349 20.3601C18.989 21.281 18.4853 21.8806 18.1235 22.1949L18.1181 22.1995L18.1129 22.2043C17.551 22.721 16.9834 22.9587 16.3983 22.9739C15.9641 22.9728 15.4018 22.8472 14.7032 22.56L14.7019 22.5594C13.9199 22.2404 13.177 22.0724 12.4772 22.0724C11.7501 22.0724 10.9889 22.239 10.1966 22.5587L10.1961 22.5589C9.46949 22.8531 8.93143 22.9856 8.55772 22.9983L8.55772 22.9983L8.5534 22.9985C8.02919 23.0208 7.45578 22.7958 6.82165 22.2062L6.81583 22.2008L6.80983 22.1956C6.41165 21.8483 5.88349 21.2235 5.22334 20.2903L5.2227 20.2894C4.53936 19.328 3.97258 18.2062 3.52595 16.9164C3.04894 15.5268 2.81348 14.1921 2.81348 12.9087C2.81348 11.4405 3.13014 10.2035 3.74263 9.18072L3.74265 9.18074L3.74507 9.17661C4.22761 8.35304 4.86519 7.70885 5.66366 7.23489C6.45963 6.76241 7.31621 6.52191 8.24451 6.50578C8.71075 6.50663 9.37176 6.65417 10.2556 6.98385C10.7124 7.15527 11.0956 7.28763 11.4017 7.37782C11.6911 7.46308 11.9617 7.52655 12.1684 7.52655C12.2802 7.52655 12.4062 7.50073 12.5132 7.47496C12.6323 7.44625 12.7764 7.40505 12.9424 7.3532C13.2749 7.24939 13.7172 7.09592 14.2657 6.89466C15.2621 6.53626 16.0567 6.40581 16.6713 6.45799L16.6734 6.45816C18.2767 6.58755 19.4836 7.15257 20.3411 8.13357C18.7813 9.24516 17.9825 10.7649 17.9996 12.6589L17.9996 12.6597C18.0166 14.2592 18.603 15.6119 19.7518 16.6775C20.1506 17.0557 20.5872 17.369 21.0628 17.6169C20.9848 17.826 20.9043 18.0295 20.821 18.2275C20.4862 19.0006 20.0911 19.7097 19.6349 20.3601ZM13.708 2.57829L13.7103 2.57567C14.0874 2.14274 14.5737 1.77459 15.179 1.47563C15.5919 1.27449 15.9791 1.13942 16.3415 1.06503C16.317 2.0427 15.9487 2.9703 15.2038 3.85484C14.3791 4.81848 13.456 5.37056 12.4994 5.43911C12.5167 4.49116 12.9384 3.44941 13.708 2.57829Z"
                  stroke="black"
                />
              </svg>
      </v-icon>
      Continue with Apple
          </button>
    <!-- Apple login -->

    <!-- Email login -->
    <button class="login-button" @click="$router.push('/email')" style="display: flex; width: 100%; height: 4rem; cursor: pointer; border: 1px solid #000000; border-radius: 10px; font-family: 'Outfit'; font-style: normal; font-weight: 600; font-size: 16px;  color: #2b2b2b;">
      <v-icon style="text-align: left; width: 35px; height: 35px;">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_8805_344445)">
                <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_8805_344445">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
              </svg>
      </v-icon>
      Continue with Email
          </button>
    <!-- Email login -->
        </div>

        <v-row justify="start">
                <v-col cols="12" class="text-left">
                    <v-sheet style="font-family: Outfit; font-weight: 300; margin-top: 15px;">
                        <p style="color:#707070; font-size:16px;">By continuing, you agree to our <span @click="$router.push('/terms')" style="color: var(--Primary400, #5157BF); text-decoration-line: underline; cursor: pointer;">Terms of Service</span> and <span @click="$router.push('/privacy')" style="color: var(--Primary400, #5157BF); text-decoration-line: underline; cursor: pointer;">Privacy Policy</span>
                        </p>
                    </v-sheet>
                </v-col>
            </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "../main";
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import {
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
} from "firebase/auth";
import { doc, getDoc, updateDoc, setDoc } from "@firebase/firestore";
import {db} from "@/main"
export default {
  name: "LoginBox",
  data() {
    return {
      email: "",
      password: "",
      username: "",
      confirm_password: "",
      stored_auth_code: "",
      loginPage: true,
      registerPage: false,
      resetPage: false,
      error_msg: "",
      loader_style: "hidden",
    };
  },
  methods: {
    displayLogin() {
      this.loginPage = true;
      this.registerPage = false;
      this.resetPage = false;
    },
    displayRegister() {
      this.loginPage = false;
      this.registerPage = true;
      this.resetPage = false;
    },
    displayReset() {
      this.loginPage = false;
      this.registerPage = false;
      this.resetPage = true;
    },

    /**
     * Displays a given error.
     */
    displayError(err) {
      this.error_msg = err;
      setTimeout(
        function () {
          this.error_msg = "";
        }.bind(this),
        3000
      );
    },
    /**Sends a password reset to a given email */
    async sendPassReset() {
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.displayError("Password reset email has been sent.");
          setTimeout(() => {
            this.displayLogin();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          let temp_err;
          switch (error.message) {
            case "Firebase: Error (auth/invalid-email).":
              temp_err = "Invalid email.";
              break;
            case "Firebase: Error (auth/network-request-failed).":
              temp_err = "It looks like you are offline. Try again later.";
              break;
            case "Firebase: Error (auth/user-not-found).":
              temp_err = "User with this email doesn't exist.";
              break;

            default:
              temp_err = "Something went terribly wrong.";
          }
          this.displayError(temp_err);
        });
    },

    /**
     * Validates user credentials and redirects to /home on success.
     * Displays an error otherwise*/
    async loginWithEmailAndPass() {
      if (this.password == "") {
        this.error_msg = " The password cannot be empty";
      } else {
        try {
          this.loader_style = "visible";
          await signInWithEmailAndPassword(auth, this.email, this.password)
            .then(() => {
              // Signed in
              this.$router.push("/home");
            })
            .catch((error) => {
              let temp_err;
              /* const errorCode = error.code; */
              const errorMessage = error.message;

              console.log(errorMessage);
              switch (error.message) {
                case "Firebase: Error (auth/invalid-email).":
                  temp_err = "Incorrect email or password.";
                  break;
                case "Firebase: Error (auth/wrong-password).":
                  temp_err = "Incorrect email or password.";
                  break;
                case "Firebase: Error (auth/user-not-found).":
                  temp_err = "User with this email doesn't exist.";
                  break;
                case "Firebase: Error (auth/user-disabled).":
                  temp_err = "User with this email has been disabled.";
                  break;
                case "Firebase: Error (auth/too-many-requests).":
                  temp_err = "Too many requests. Try again later.";
                  break;
                case "Firebase: Error (auth/operation-not-allowed).":
                  temp_err =
                    "Signing in with Email and Password is not enabled.";
                  break;
                case "Firebase: Error (auth/network-request-failed).":
                  temp_err = "It looks like you are offline. Try again later.";
                  break;
                default:
                  temp_err = "Something went terribly wrong.";
              }
              this.displayError(temp_err);
              this.loader_style = "hidden";
            });
        } catch (error) {
          console.log(error);
        }
      }
    },

    /**Logs the user in using Google */
    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        const displayName = user.displayName

        console.log('Signed in with Google:', displayName);

        const firstName = displayName.split(' ')[0]
        const lastName = displayName.split(' ')[1]

        localStorage.setItem('firstName', firstName);
        localStorage.setItem('lastName', lastName);

        this.$router.push("/home");
      } catch (error) {
        console.log(error);
        this.error_msg = error.message;
      }
    },

    async signInWithFacebook() {
      console.log("signing in with facebook");
      const provider = new FacebookAuthProvider();
      try {
        let data = await signInWithPopup(auth, provider);

        console.log(data);

        this.$router.push("/home");
      } catch (error) {
        console.log(error);
        this.error_msg = error.message;
      }
    },

    /**
     * Validates all fields and creates a user with a given name and password.
     */
    async submitRegisterForm() {
      if (this.confirm_password != this.password) {
        this.error_msg = "Passwords do not match.";
      } else if (
        this.confirm_password == "" ||
        this.password == "" ||
        this.username == ""
      ) {
        this.displayError("Fields cannot be empty.");
      } else {
        try {
          await createUserWithEmailAndPassword(auth, this.email, this.password);
          sendEmailVerification(auth.currentUser)
            .then(() => {
              // Email verification has been sent.
            })

          await updateProfile(auth.currentUser, {
            displayName: this.username,
          });

          this.$router.push("/home");
        } catch (error) {
          let temp_err;
          console.log(error);

          switch (error.message) {
            case "Firebase: Error (auth/email-already-in-use).":
              temp_err =
                'This email is already registered. If you forgot your password click on "Forgot Password?"';
              break;
            case "Firebase: Error (auth/invalid-email).":
              temp_err = "Invalid email.";
              break;
            case "Firebase: Error (auth/operation-not-allowed).":
              temp_err = "Email/Password sign-in is currently disabled.";
              break;
            case "Firebase: Error (auth/weak-password).":
              temp_err = "Your password is too weak.";
              break;
            case "Firebase: Error (auth/network-request-failed).":
              temp_err = "It looks like you are offline. Try again later.";
              break;
            default:
              temp_err = "Something went terribly wrong.";
          }
          this.displayError(temp_err);
        }
      }
    },
  },
  computed: {
    loginBtnColor() {
      if (this.resetPage) {
        return this.email && this.email.length > 0 ? "#c9ccec" : "#5157BF";
      } else if (this.registerPage) {
        return "#c9ccec";
      } else {
        return "#c9ccec";
      }
    },
    isEnabled() {
      if (this.resetPage) {
        return this.email;
      } else if (this.registerPage) {
        return (
          this.email &&
          this.username &&
          this.password &&
          this.confirm_password &&
          this.password == this.confirm_password
        );
      } else {
        return this.email && this.password;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700&family=Outfit:wght@600&display=swap");
.content {
  width: 100%;
  height: 100%;
  display: flex;
 
  font-family: "Outfit";
  font-style: normal;
  justify-content: center;
  background: var(--Primary50, #F9FAFF);
}

.error {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  /* or 120% */

  letter-spacing: -0.5px;

  color: #000000;
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    width: 50px;
  }
}
#align-left {
  text-align: left;
  padding: 0;
}

.login-box {
  display: flex;
  max-width: 450px;
  width: 100%;
  background: var(--Base0, #FFF);
  border: 1px solid var(--Base40, #DDD);
  border-radius: 16px;
  flex-direction: column;
  overflow: hidden;
  padding: 2.5rem 10px;
  align-items: center;
  margin-right: 200px;
  align-self: center;
  
}
.header {
  width: 83%;
  h5 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 145%;
    /* or 29px */


    /* Base100 */

    color: #2b2b2b;
    margin: 0;
    padding: 0px 0;
    span {
      color: #6970c9;
      font-weight: 500;
    }
  }
  #text {
    width: 80%;

    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    color: #000000;
    text-align: left;
  }
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* identical to box height, or 18px */

  color: #000000;
  margin-top: 2rem;
  gap: 10px;

  svg {
    width: 30%;
  }
}

.login-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;

  form {
    width: 85%;
  }
}

.login-field {
  margin-top: 2%;
  position: relative;
  font-weight: 100;
  font-family: "Outfit";
}

.login-field input {
  border: 1px solid #a0a0a0;

  border-radius: 10px;
  text-align: left;
  text-indent: 1em;
  width: calc(100% - 10px);

  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  height: 60px;

  color: #707070;
}

.login-field input:focus,
.login-field input:active {
  outline: none;
}

.login-field input::placeholder,
.login-field input::placeholder {
  font-weight: 300;
  font-size: 15px;

  align-content: center;
}

.login-button {
  width: 100%;
  font-weight: 500;
  padding: 15px 32px;
  border: none;
  letter-spacing: 1px;
  cursor: pointer;

  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* or 18px */

  text-align: center;

  color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-password {
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  padding: 6px 0;
  /* color: var(--base-color-400); */
  cursor: pointer;
  text-decoration: underline;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* or 120% */

  letter-spacing: -0.5px;
  text-decoration-line: underline;

  color: #000000;
}

.signup-btn {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  color: #000000;

  cursor: pointer;

  span {
    text-decoration: underline;
  }
}
.signup-btn a {
  text-decoration: none;
  color: var(--base-color-400);
  cursor: pointer;
}

/*
Loader
*/
.loader-box {
  margin-top: none;
  padding-top: 40px;
  padding-left: 4px;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: none;
}

.loader-element {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-element div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 8px;
  background: rgb(208, 100, 230);
  -webkit-animation: loader-element 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation: loader-element 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  border-radius: 10px;
}
.loader-element div:nth-child(1) {
  left: 8px;
  -webkit-animation-delay: -0.24s;
  animation-delay: -0.24s;
}
.loader-element div:nth-child(2) {
  left: 32px;
  -webkit-animation-delay: -0.12s;
  animation-delay: -0.12s;
}
.loader-element div:nth-child(3) {
  left: 56px;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
@-webkit-keyframes loader-element {
  0% {
    top: 16px;
    height: 8px;
  }
  50% {
    top: 32px;
    height: 16px;
  }
  100% {
    top: 8px;
    height: 16px;
  }
}
@keyframes loader-element {
  0% {
    top: 16px;
    height: 8px;
  }
  50% {
    top: 32px;
    height: 16px;
  }
  100% {
    top: 8px;
    height: 16px;
  }
}

/*
End of Loader2
*/

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .content {
    background: #ffffff;
  }
  .login-box {
    width: 100%;
  }
  .login-wrap {
    width: 100%;
  }

  .login-field input {
    width: 100%;
  }

  .login-box {
    margin: 1rem 4rem;
    width: 80%;
    overflow-y: scroll;
  }
  .login-field input,
  .login-button {
    height: 40px;
  }
  
  .divider {
    gap: 5px;
  }
  .provider {
    padding: 6px 15px;
    font-size: 13px;
  }
}
</style>