<template>
  <div className="six_month_graph">
    <div v-if="!isConnectedToBankAccount">
      <div class="empty-snapshot">
        Create a budget to view 6-month summary of your spending by category
      </div>
      <div class="month-buttons">
        <div
          v-for="month in lastSixMonths"
          :key="month"
          :class="{ selected: month === selectedMonth }"
          @click="selectMonth(month)"
        >
          {{ month }}
        </div>
      </div>
    </div>    

    <canvas id="myChart" v-if="isConnectedToBankAccount"></canvas>

  </div>
</template>

<script>
import Chart from 'chart.js/auto';
// import AnnotationPlugin from 'chartjs-plugin-annotation'
import plaidService from '../../services/PlaidService.ts';
import { auth, db } from '@/main'
import {
  doc,
  getDocs,
  collection,
} from "@firebase/firestore";
// creating a plugin for a background image
const xSquaredButton = {
id: 'xSquareButton',
beforeDraw(chart, args, pluginOptions) {
  const { ctx, data, scales: { x, y } } = chart;

  ctx.save();

  ctx.lineWidth = 2;

  const textWidth2 = ctx.measureText(data.labels[5]).width / 2 + 4;
  const cornerRadius = 3;

  ctx.beginPath();
  ctx.fillStyle = 'rgba(131, 129, 211, 1)';
  ctx.strokeStyle = 'rgba(131, 129, 211, 1)';
  
  // Move to the starting point with rounded corners
  ctx.moveTo(x.getPixelForValue(5) - textWidth2 + cornerRadius, x.top + 8);
  
  // Draw the top line with rounded corners
  ctx.arcTo(x.getPixelForValue(5) + textWidth2, x.top + 8, x.getPixelForValue(5) + textWidth2, x.top + 26, cornerRadius);
  
  // Draw the right line with rounded corners
  ctx.arcTo(x.getPixelForValue(5) + textWidth2, x.top + 26, x.getPixelForValue(5) - textWidth2, x.top + 26, cornerRadius);

  // Draw the bottom line with rounded corners
  ctx.arcTo(x.getPixelForValue(5) - textWidth2, x.top + 26, x.getPixelForValue(5) - textWidth2, x.top + 8, cornerRadius);
  
  // Draw the left line with rounded corners
  ctx.arcTo(x.getPixelForValue(5) - textWidth2, x.top + 8, x.getPixelForValue(5) - textWidth2 + cornerRadius, x.top + 8, cornerRadius);

  ctx.closePath();
  ctx.fill();
  ctx.stroke();
}
};

export default {
  data(){
    return {
      isConnectedToBankAccount: false,
      lastSixMonths: [],
      selectedMonth: null,
    }
  },
  async mounted() {
  this.getLastSixMonths()
  await this.findUserBankAccount()
  if(this.isConnectedToBankAccount){
  const ctx = document.getElementById('myChart');

  // Getting the current month
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  // Generating labels for the last 5 months and the current month
  const monthLabels = [];
  for (let i = 5; i > 0; i--) {
    const monthIndex = (currentMonth - i + 12) % 12; // Ensure the month index is between 0 and 11
    const monthAbbreviation = this.getMonthAbbreviation(monthIndex);
    monthLabels.push(monthAbbreviation);
  }

  const lastMonthAbbreviation = this.getMonthAbbreviation(currentMonth);
  monthLabels.push(lastMonthAbbreviation);

  // Chart.register(AnnotationPlugin);
    Chart.register(xSquaredButton);
  
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: monthLabels,
        datasets: [
          {
            type: 'line',
            label: 'line Segment',
            data: [2000, 2000, 2650, 2650, 2650, 2650, 2650],
            borderColor: '#c9ccec',
            borderWidth: 2,
            borderDash: [2, 3],
            fill: false,
            pointRadius: 0,
            spanGaps: true,
            // pointHoverRadius: 0,
            legend: {
              display: false
            },
            backgroundColor: 'blue'
          },
          {
            label: 'Utilities',
            data: [480, 600, 750, 900, 800, 500],
            backgroundColor: '#009EDF',
            borderWidth: 0,
            stack: 'stack',
            barThickness: 28,
          },
          {
            label: 'Housing',
            data: [300, 180, 250, 250, 250, 100],
            backgroundColor: '#FF5733',
            borderWidth: 0,
            stack: 'stack',
            barThickness: 28,
          },
          {
            label: 'Entertainment',
            data: [200, 130, 400, 450, 225, 100],
            backgroundColor: '#FF8301',
            borderWidth: 0,
            stack: 'stack',
            barThickness: 28,
          },
          {
            label: 'Transportation',
            data: [350, 70, 300, 300, 300, 100],
            backgroundColor: '#FFB900',
            borderWidth: 0,
            stack: 'stack',
            barThickness: 28,
          },
          {
            label: 'Food',
            data: [100, 100, 70, 125, 125, 100],
            backgroundColor: '#213B80',
            borderWidth: 0,
            stack: 'stack',
            barThickness: 28,
          },
          {
            label: 'Other',
            data: [250, 250, 250, 250, 300, 150],
            backgroundColor: '#984F88',
            borderWidth: 0,
            stack: 'stack',
            barThickness: 28,
          },
        ],
      },
      options: {
      scale: {
        pointLabels :{
          fontStyle: "bold",
        }
      },
      aspectRatio: 1,
      plugins: {
        xSquareButton: xSquaredButton, 
        annotation: {
          annotations: {
            label1: {
              type: 'label',
              xValue: 4.4,
              yValue: 2785,
              color: '#6970C9',
              padding: 0,
              backgroundColor: 'white',
              content: ['2K Budget'],
              font: {
                size: 12.5,
                weight: 'bold',
                family: 'Arial'
              }
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 2,
            boxHeight: 4,
            filter: function(item, chart) {
                  return !item.text.includes('line Segment');
              }
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: (context) => {
              // Check if the label has the 'last-month-label' class
              const isLastMonthLabel = monthLabels[context.index] === lastMonthAbbreviation;
              return isLastMonthLabel ? 'white' : '#838BD3';
            },
            font: {
              family: 'Montserrat',
              size: 10
            },
            display: true,
            callback: (value, index) => {
              // Display the actual label text
              return monthLabels[index];
            },
          },
          grid: {
            borderColor: 'white',
            drawBorder: false,
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 3000,
          ticks: {
            stepSize: 1500,
            callback: (value) => {
              if (value === 1500) return '1.5k';
              if (value === 3000) return '3k';
            },
            display: true,
            color: '#838BD3',
          },
          grid: {
          drawTicks: false,
          drawBorder: false,
          display: true,
          lineWidth: 0.5,
          },
      },
      },
    },
  });
  }

  // plaidService.connectPlaidAccount(this.receivePlaidData);
},
methods: {
  // receivePlaidData(plaidData) {
  //   const { transactionsData } = plaidData;
  //   console.log("Received transactions data in SixMonthSnapShot.vue", transactionsData);
  // },
  getMonthAbbreviation(monthIndex) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[monthIndex];
  },
  async findUserBankAccount(){
      if(auth.currentUser){
        const userUID = auth.currentUser.uid
        console.log(userUID)
        const userDocRef = doc(db, "Real-Users", auth.currentUser.uid);
        try {
          const bankingCollectionRef = collection(userDocRef, "banking");
          const bankingQuerySnapshot = await getDocs(bankingCollectionRef);
          console.log("bankingQuerySnapshot:", bankingQuerySnapshot); // Log the snapshot
          if(bankingQuerySnapshot.docs && bankingQuerySnapshot.docs.length > 0){
            console.log('User exist')
            this.isConnectedToBankAccount = true;
          } else {
            console.log('User does not exist')
          }
        } catch(error) {
          console.log(error)
        }
      } else return
    },
    getLastSixMonths() {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const now = new Date();
      this.lastSixMonths = [];

      for (let i = 0; i < 6; i++) {
        const monthIndex = (now.getMonth() - i + 12) % 12;
        this.lastSixMonths.push(monthNames[monthIndex]);
      }

      // Reverse the array to get the months in chronological order
      this.lastSixMonths.reverse();

      // Select the first month by default
      this.selectedMonth = this.lastSixMonths[0];
    },
    selectMonth(month) {
      this.selectedMonth = month;
    }
},
};
</script>
<style>
.six_month_graph {
  border: 1px solid #DCDCDD;
  border-radius: 12px;
  padding: 1rem;
  margin: 1rem 0;
}
.empty-snapshot {
  font-size: small;
  margin-right: 20%;
  margin-left: 20%;
  margin-top: 20%;
  margin-bottom: 20%;
}
.month-buttons {
  display: flex;
  font-size: small;
  color: #838AD2;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  cursor: pointer;
}
.selected {
  background-color: #838AD2;
  padding: 0 3px 0 3px;
  color: white;
  border-radius: 5px;
}
</style>
