import axios, { AxiosResponse } from 'axios';
import { auth } from "@/main";
import { store } from "@/store";

import parseGetTransactions from './ParseGetTransactions';

const baseUrl = 'https://backendprod-uza6jon7lq-uc.a.run.app/auth/';

const getPlaidTransactions = async (data: any) => {
    const uid = auth.currentUser && auth.currentUser.uid;

    let transactionsData;
    let isStatus200 = false;

    while (!isStatus200) {
        try {
            // Clone the data object to remove circular references
            const requestData = JSON.parse(JSON.stringify({
                'user_id': uid,
                'access_token': data.access_token
            }));

            const response: AxiosResponse = await axios({
                method: 'POST',
                url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/get_transactions",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                data: requestData,
            });

            if (response.status === 200) {
                console.log("transactions data", response.data);
                transactionsData = response.data;
                isStatus200 = true;

            } else {
                console.error("Error: Unexpected status code from get_transactions:", response.status);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const responseStatus = error.response?.status;
                if (responseStatus === 400) {
                    console.error("Error: Bad Request from get_transactions:", responseStatus);
                    break; // Exit the loop on error
                } else {
                    console.error("Error in getPlaidTransactions:", responseStatus);
                }
            } else {
                console.error("Error in getPlaidTransactions:", error);
            }
        }
    }

    return transactionsData;
}


const connectPlaidAccount = async (callback: (data: any) => void): Promise<any> => {
    try {
        const url = baseUrl + `create_link_token`;
        const uid = auth.currentUser && auth.currentUser.uid;

        // Get the link token
        const response = await axios.post(url, { user_id: uid });
        const linkToken = response.data.link_token;

        console.log("Link token response", linkToken);

        const Plaid = (<any>window).Plaid;
        const handler = Plaid.create({
            clientName: 'Your App Name',
            env: 'production',
            product: ['transactions'],
            token: linkToken,
            onSuccess: async function (publicToken: any, metadata: any) {
                try {
                    // Exchange public token for access token
                    const exchangeResponse = await axios.post("https://backendprod-uza6jon7lq-uc.a.run.app/auth/exchange_public_token", { public_token: publicToken });
                    const accessToken = exchangeResponse.data.accessToken;

                    // await new Promise(resolve => setTimeout(resolve, 21000));

                    const transactionsData = await getPlaidTransactions({ user_id: response, access_token: accessToken });

                    // // Client authorization
                    // const authResponse = await axios.post("https://backenddev-uza6jon7lq-uc.a.run.app/auth/client_authorization", { access_token: accessToken, user_id: uid });

                    if (callback && typeof callback === 'function') {
                        console.log('Here ', transactionsData);
                        callback({ publicToken, metadata, accessToken, transactionsData });
                    }

                    const userId = await runCreateUser(uid);

                    if (userId) {
                        runUpdateUser(userId);

                        const budget_id = await runCreateBudget(userId);

                        store.commit('set_selected_budgetId', budget_id);

                        console.log("saved get_selected_budgetId: ", store.getters.get_selected_budgetId)

                        // await this.fetchGetTransactionsData(this.selectedCategory, budget_id);

                        // const refresh_transactions_data = {
                        //     user_id: uid,
                        //     budget_id: budget_id
                        // }

                        // await refresh_transactions(refresh_transactions_data);

                        const categoryId = await runCreateCategory(userId, budget_id);

                        const transactionId = await runCreateTransaction(userId, budget_id);

                        // await runUpdateTransaction(userId, budget_id, transactionId);

                        await runUpdateCategoryAmount(userId, budget_id, categoryId);

                        await runCreateUserWithUserID(userId);

                        // const getSixMonthsTransactionsData = {
                        //     userId: uid,
                        // }

                        // console.log('getSixMonthsTransactions', getSixMonthsTransactionsData);
                        // await getSixMonthsTransactions(getSixMonthsTransactionsData);

                    } else {
                        console.error("User creation failed.");
                    }

                } catch (error) {
                    console.error("Error in Plaid Link flow:", error);
                }
            },
        });

        handler.open(); // Open the Plaid Link dialog

    } catch (error) {
        console.error("Error in connectPlaidAccount:", error);
    }
};

const createUsers = async (userData: any): Promise<string | undefined> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'POST',
            url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/createUsers",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: userData,
        });

        if (response.status === 200 || response.status === 201 && response.data && response.data.userId) {
            console.log("User created successfully. UserID:", response.data.userId);
            console.log('working: ', userData)
            return response.data.userId;
        } else {
            console.error("Error creating user. Unexpected response:", response);
        }
    } catch (error) {
        console.error("Error creating user:", error);
    }

    return undefined;
};

const runCreateUser = async (user_id: any): Promise<any> => {
    const userData = {
        first_name: store?.state.user.first_name,
        last_name: store?.state.user.last_name,
        user_id,
        gender: store?.state.user.gender,
        email: "",
        race: "",
        birthday: store?.state.user.birthday,
        profile_photo: "",
        notification: "",
        onboarded: ""
    };

    // Create user and get userId
    const userId = await createUsers(userData);

    console.log('INSIDE CREATEUSER OBJECT ', userData);

    return userId;
}

const updateUser = async (userId: string, updatedUserData: any): Promise<void> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'PUT',
            url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/updateUsers",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                userId,
                ...updatedUserData,
            },
        });

        if (response.status === 200 && response.data && response.data.message) {
            console.log("User updateUser successfully. Message:", response.data.message);
            window.location.reload();
        } else {
            console.error("Error updating user. Unexpected response:", response);
        }
    } catch (error) {
        console.error("Error updating user:", error);
    }
};

const runUpdateUser = async (user_id: any) : Promise<any> => {
    const updatedUserData = {
        user_id,
        first_name: store?.state.user.first_name,
        last_name: store?.state.user.last_name,
        gender: store?.state.user.gender,
        race: "",
        birthday: store?.state.user.birthday,
        profile_photo: "",
        notification: "",
        onboarded: store?.state.user.onboarded
    }; 

    await updateUser(user_id, updatedUserData);
}

const createBudget = async (userId: string, updatedUserData: any): Promise<void> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'POST',
            url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/createBudget",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                userId,
                ...updatedUserData,
            },
        });

        if (response.status === 201 || response.status === 200 && response.data && response.data.budgetId) {
            console.log("response.data.budgetId", response.data.budgetId);
            return response.data.budgetId;
        }
    } catch (error) {
        console.error("Error createBudget:", error);
    }
};

const runCreateBudget = async (userId: any) : Promise<any> => {
    const budgetData = {
        userId: userId,
        // DO NOT DELETE => THIS IS WHAT THE DATA SHOULD LOOK LIKE ONCE WE GET DATA FROM USER
        // name: store?.state.selected_budget ? store?.state.selected_budget : "My Budget",
        // amount: store?.state.budgets[0].amount, 
        name: store?.state.selected_budget ? store?.state.selected_budget : "My Budget",
        amount: store?.state.selected_budget.averageExpenses ? store?.state.selected_budget.averageExpenses : 3000
      }

    const budget_id = await createBudget(userId, budgetData);

    return budget_id
}

const createCategory = async (userId: string, createCategoryData: any): Promise<void> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'POST',
            url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/createCategory",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                userId,
                ...createCategoryData,
            },
        });

        if ((response.status === 200 || response.status === 201)  && response.data && response.data.categoryId) {
            console.log("response.data.budgetId", response.data.categoryId);
            return response.data.categoryId;
        } else {
            console.error("Error in createCategory. Unexpected response:", response);
        }
    } catch (error) {
        console.error("Error createCategory:", error);
    }
};


const runCreateCategory = async (userId: any, budgetId : any) : Promise<any> => {
// DO NOT DELETE => THIS IS WHAT THE DATA SHOULD LOOK LIKE ONCE WE GET DATA FROM USER
// const categories = store?.state.selected_budget.categories;

    // DO NOT DELETE => THIS IS WHAT THE DATA SHOULD LOOK LIKE ONCE WE GET DATA FROM USER
    // if (categories && categories.length > 0) {
        const createCategoryData = {
            userId: userId,
            budgetId: budgetId,
            // DO NOT DELETE => THIS IS WHAT THE DATA "SHOULD" LOOK LIKE ONCE WE GET DATA FROM USER
            // name: categories[0].name,
            // amount: categories[0].amount,
            // category_type: categories[0].category_type,
            // expense_type: categories[0].expense_type
            name: "Childcare",
            amount: 100,
            category_type: "Childcare",
            expense_type: "all",
            image: ""
        };
    //}

    const categoryId = await createCategory(userId, createCategoryData);

    return categoryId;
}

const createTransaction = async (userId: string, createCategoryData: any): Promise<void> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'POST',
            url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/createTransaction",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                userId,
                ...createCategoryData,
            },
        });

        if (response.status === 200 || response.status === 201 && response.data && response.data.transactionId) {
            console.log("response.data.transactionId", response.data.transactionId);
            return response.data.transactionId;
        } else {
            console.error("Error in createTransaction. Unexpected response:", response);
        }
    } catch (error) {
        console.error("Error createTransaction:", error);
    }
};

const runCreateTransaction = async (userId: any, budgetId : any) : Promise<any> => {
    const createTransactionData = {
        userId: userId,
        budgetId: budgetId,
        name: "Apple MacBook",
        amount: 1599,
        notes:"good",
        category_type: "Entertainment",
        payment_type: "card"
    }

    const transactionId = await createTransaction(userId, createTransactionData);

    return transactionId;
}


const updateTransaction = async (userId: string, updateTransactionData: any): Promise<void> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'PUT',
            url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/updateTransaction",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                userId,
                ...updateTransactionData,
            },
        });

        if (response.status === 200 || response.status === 201 && response.data && response.data.message) {
            console.log("response.data.message in updateTransaction: ", response.data.message);
        } else {
            console.error("Error in updateTransaction. Unexpected response:", response);
        }
    } catch (error) {
        console.error("Error updateTransaction:", error);
    }
};

const runUpdateTransaction = async (userId: any, budgetId : any, transactionId: any) : Promise<any> => {
    const updateTransactionData = {
        userId: userId,
        // budgetId: budgetId,
        transactionId: transactionId,
        category: "Education"
    }

    await updateTransaction(userId, updateTransactionData);
}

const updateCategoryAmount = async (userId: string, updateCategoryAmountData: any): Promise<void> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'PUT',
            url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/updateCategoryAmount",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                userId,
                ...updateCategoryAmountData,
            },
        });

        if (response.status === 200 || response.status === 201 && response.data && response.data.message) {
            console.log("response.data.message in updateCategoryAmount: ", response.data.message);
        } else {
            console.error("Error in updateCategoryAmount. Unexpected response:", response);
        }
    } catch (error) {
        console.error("Error updateCategoryAmount:", error);
    }
};

const runUpdateCategoryAmount = async (userId: any, budgetId : any, categoryId: any) : Promise<any> => {

    const updateCategoryAmountData = {
        userId: userId,
        budgetId: budgetId,
        categoryId: categoryId,
        newAmount: store?.state.selected_budget.averageExpenses ? store?.state.selected_budget.averageExpenses : 1
    }

    await updateCategoryAmount(userId, updateCategoryAmountData);
}

const calculateCategoryAmount = async (userId: string, categoryType: string): Promise<any> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'PUT',
            url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/calculateCategoryAmount",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                userId,
                categoryType,
            },
        });

        // if (response.status === 200 || response.status === 201 && response.data && response.data.amount) {
            console.log("response.data.amount in calculateCategoryAmount: ", response.data.total_amount);
            return response.data.total_amount.toFixed(2);
        // } else {
        //     console.error("Error in calculateCategoryAmount. Unexpeted response:", response);
        // }
    } catch (error) {
        console.error("Error calculateCategoryAmount:", error);
    }
};

const runCalculateCategoryAmount = async (userId: any, budgetId : any, transactionId: any) : Promise<any> => {
    const updateTransactionData = {
        userId: userId,
        budgetId: budgetId,
        transactionId: transactionId,
        category_type: "Education"
    }

    await updateTransaction(userId, updateTransactionData);
}

const createUserWithUserID = async (userId: string, createUserWithUserID: any ): Promise<void> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'POST',
            url: `https://backendprod-uza6jon7lq-uc.a.run.app/auth/create-user/${userId}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: createUserWithUserID,
        });

        if (response.status === 201 || response.status === 200 && response.data && response.data.message) {
            console.log("response.data.message in createUserWithUserID: ", response.data.message);
        } else {
            console.error("Error in createUserWithUserID. Unexpected response:", response);
        }
    } catch (error) {
        console.error("Error createUserWithUserID:", error);
    }
};

const runCreateUserWithUserID = async (userId: any) : Promise<any> => {
    const createUserWithUserIDData  = {
        first_name: store?.state.user.first_name,
        last_name: store?.state.user.last_name,
        email: "aidenpeace300@gmail.com",
        gender: store?.state.user.gender,
        race: "",
        birthday: store?.state.user.birthday,
        profile_photo: "",
        notification: "",
        onboarded: store?.state.user.onboarded
    }; 

    // for some reason this call is shown as the first half of userId in the network tab (check console to see more!)
    await createUserWithUserID(userId, createUserWithUserIDData);

}


const refresh_transactions = async ( refresh_transactions_data: any ): Promise<void> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'POST',
            url: `https://backendprod-uza6jon7lq-uc.a.run.app/auth/refresh_transactions`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: 
                refresh_transactions_data,
        });

        if (response.status === 201 || response.status === 200 && response) {
            console.log("response.data.message in refresh_transactions: ", response.data.transactions);
        } else {
                console.error("Error in refresh_transactions. Unexpected response:", response);
            }
        } catch (error) {
            console.error("Error refresh_transactions:", error);
        }
};


const getSixMonthsTransactions = async (userId: string): Promise<any[]> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'POST',
            url: "https://backendprod-uza6jon7lq-uc.a.run.app/auth/get_categorized_transactions",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                userId: userId,
            },
        });

        if (response.status === 200 || response.status === 201 && response.data) {
            console.log("response.data.message in getSixMonthsTransactions: ", response.data);
            return response.data;
        } else {
            console.error("Error in getSixMonthsTransactions. Unexpected response:", response);
            return [];
        }
    } catch (error) {
        console.error("Error getSixMonthsTransactions:", error);
        return [];
    }
};

const deleteTransaction = async (deleteTransactionObject: any ): Promise<void> => {
    try {
        const response: AxiosResponse = await axios({
            method: 'DELETE',
            url: `https://backendprod-uza6jon7lq-uc.a.run.app/auth/deleteTransaction`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: deleteTransactionObject,
        });

        if (response.status === 201 || response.status === 200 && response.data && response.data.message) {
            console.log("response.data.message in deleteTransaction: ", response.data.message);
        } else {
            console.error("Error in createUserWithUserID. Unexpected response:", response);
        }
    } catch (error) {
        console.error("Error deleteTransaction:", error);
    }
};

export default { connectPlaidAccount, getPlaidTransactions, createUsers, updateUser, createBudget, createCategory, createTransaction, updateTransaction, 
    updateCategoryAmount, calculateCategoryAmount, createUserWithUserID, refresh_transactions, getSixMonthsTransactions, runCreateUser, runUpdateUser, 
    runCreateBudget, runCreateCategory, runCreateTransaction, runUpdateTransaction, runUpdateCategoryAmount, runCalculateCategoryAmount, 
    runCreateUserWithUserID, deleteTransaction};