import axios, { Axios, AxiosResponse } from "axios";
import { auth } from "@/main";
import { store } from "@/store";

const baseUrl = "https://backenddev-uza6jon7lq-uc.a.run.app/auth/rules/";

const storeRules = async (data: any) => {
  if(store.state.rules.length === 0 ) {
    store.commit("add_rules", data.rules);
  }
};

const getRules = async () => {
  try {
    const userUid = auth.currentUser && auth.currentUser.uid;

    const response: AxiosResponse = await axios.get(`${baseUrl}${userUid}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    console.log("RULES FROM GETRULES:", response.data);
    await storeRules(response.data);
    return response.data;
  } catch (error: any) {
    console.error("Error Fetching Rules:", error.message);
    throw error;
  }
};

const submitRules = async (rulesFormData: any) => {
  try {
    console.log("IN SUBMIT RULES", rulesFormData)
    const userUid = auth.currentUser && auth.currentUser.uid;

    const response: AxiosResponse = await axios ({
      method: 'POST',
      url: `${baseUrl}${userUid}`,
      data: {
        "name": rulesFormData.ruleName,
        "ifStatements": rulesFormData.ifStatements,
        "thenStatements": rulesFormData.thenStatements
      }
    })

    if(response.status === 200) {
      console.log("Rules Submit Data Successful", response.data)
      location.reload()
      return;
    } else {
      console.log("ERROR Submitting rules data")
    }
  } catch(error) {
    console.log(error)
  }
}

const editRules = async (rulesFormData: any) =>{
  try {
    console.log("IN EDIT RULES", rulesFormData)
    let selectedRuleId;
    const userUid = auth.currentUser && auth.currentUser.uid
    if (store.state.selectedRule && 'id' in store.state.selectedRule) {
      selectedRuleId = (store.state.selectedRule as { id: string }).id;
    }  
    console.log("IN EDIT RULES.selected rule", selectedRuleId)
    const response: AxiosResponse = await axios({
      method: 'PUT',
      url: `${baseUrl}${userUid}/${selectedRuleId}`,
      data: {
        "name": rulesFormData.ruleName,
        "ifStatements": rulesFormData.ifStatements,
        "thenStatements": rulesFormData.thenStatements
      }
    })

    if(response.status === 200){
      console.log("RULES EDIT DATA SUCCESSFUL", response.data)
      location.reload()
      return;
    }
  } catch(error) {
    console.log(error)
  }
}

const deleteRules = async (ruleId: any) => {
  try {
    let selectedRuleId;
    const userUid = auth.currentUser && auth.currentUser.uid
    if (store.state.selectedRule && 'id' in store.state.selectedRule) {
      selectedRuleId = (store.state.selectedRule as { id: string }).id;
    }  

    const response: AxiosResponse = await axios({
      method: "DELETE",
      url: `${baseUrl}${userUid}/${selectedRuleId}`
    })

    if(response.status === 200){
      console.log("RULE SUCCESSFULLY DELETED")
      location.reload()
      return;
    } else {
      console.log(response.status)
    }
  } catch (error) {
    console.log(error)
  }
}

export { getRules, submitRules, editRules, deleteRules };
