<template>
  <EditExpensePopup v-if="$store.state.display_edit_expense_modal">
  </EditExpensePopup>
  
  <div class="uncategorized-popup-wrapper">
    <div class="uncategorized-popup-container">
      <div class="uncategorized-header-container">
        <h2 class="uncategorized-text">
          {{ this.$store.state.clicked_category_name.categoryName }}
        </h2>
        <i
          class="pi pi-times"
          @click="handleClose()"
        ></i>
      </div>
      <div class="uncategorized-description-container">
        <p class="uncategorized-description-text">
          Items with memos that are not able to be categorized are listed below.
          Assign them to an exisiting category or create a new one, and we'll
          remember it for future transcations.
        </p>
      </div>
      <div class="transactions-card-header-container">
        <h3 class="transactions-header-text">Keyword Items ({{ transactionsCount }})</h3>
        <div class="transactions-header-text">Est. budget</div>
      </div>
      <div
        v-if="transactions.length > 0"
        class="uncategorized-transactions-card-container"
      >
        <div class="uncategorized-transactions-card" v-for="(transaction, index) in transactions" :key="index" @click="selectTransaction(transaction.name)">
          <div class="uncategorized-left-column">
            <div class="transaction-name">{{ shortenName(transaction.name) }}</div>
            <div class="transaction-suggested-category">{{ transaction.suggestedCategory }}</div>
          </div>
          <div class="uncategorized-right-column">
            <div class="transaction-est-budget">${{ transaction.estBudget.toFixed(2) }}</div>
            <i class="pi pi-angle-right" @click="openEditExpense()"></i>
          </div>
        </div>
      </div>
      <p v-else class="empty-transactions-message">Transactions empty!</p>
      <div class="uncategorized-button-container" @click="handleClose()">
        <button class="uncategorized-save-button" @click="handleClose()">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import EditExpensePopup from "./EditExpensePopup.vue";
import { collection, db } from "@/main";
import parseGetTransactions from "../../services/ParseGetTransactions.ts";
import { store, DeleteExpenseDetails } from "@/store";

export default defineComponent({
  name: "UncategorizedPopup",
  props: ["categoryName"],
  data() {
    return {
      transactions: [
        // {name: "Buffalo Exchange ca12", suggestedCategory: "Shopping", estBudget: 30},
        // {name: "BKST-U-PENN", suggestedCategory: "Shopping", estBudget: 30},
        // {name: "BB-MON-XXX", suggestedCategory: "N/A", estBudget: 30}
      ],
    };
  },
  components: { EditExpensePopup },
  mounted() {
    this.fetchGetTransactionsData();
  },
  beforeMount() {
    this.$store.state.display_edit_expense_modal = false;
  },
  computed: {
    transactionsCount() {
      return this.transactions.length;
    }
  },
  methods: {
    shortenName(name){
        const maxLength = 35;
        if (name.length > maxLength) {
          return name.substring(0, maxLength) + '...';
        } else {
          return name;
        }
    },
    handleClose(){
      this.$store.dispatch('toggle_uncategorized_modal')
      this.$store.state.clicked_category_name = null;
    },
    openEditExpense() {
      this.$store.dispatch("toggle_edit_expense_modal");
    },
    selectTransaction(transactionName) {
      this.$store.commit('setSelectedTransactionName', transactionName);
      console.log("transactionName", transactionName)
    },
    async fetchGetTransactionsData() {
      const deleteExpenseDetails = store.state.deleteExpenseDetails;
      console.log("DELETE STUFF:", deleteExpenseDetails);
      
      const groupedTransactions = await parseGetTransactions();
      console.log("groupedTransactions", groupedTransactions);
      
      if (groupedTransactions) {
        const transactions = [];

        for (const category in groupedTransactions) {
          // Check if the category is in deleteExpenseDetails
          if (deleteExpenseDetails.categories && deleteExpenseDetails.categories[category]) {
            console.log(`Category ${category} is in deleteExpenseDetails, skipping...`);
            continue;
          }

          for (const name in groupedTransactions[category]) {
            const transactionData = groupedTransactions[category][name];
            const totalAmount = transactionData.transactions.reduce(
              (acc, transaction) => acc + transaction.amount,
              0
            );

            const estBudget = totalAmount;

            // Add the transaction to the list
            if (
              category === this.$store.state.clicked_category_name.categoryName
            ) {
              transactions.push({
                name,
                suggestedCategory: category,
                estBudget,
              });
            }
          }
        }

        this.transactions = transactions;
      } else {
        console.log("No grouped transactions found.");
      }
    },
  },
});
</script>

<style>
.uncategorized-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.uncategorized-popup-container {
  position: absolute;
  top: 10%;
  background-color: white;
  border-radius: 8px;
  width: 45%;
  padding: 20px;
}

.uncategorized-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;

  .uncategorized-text {
    font-weight: 550;
    font-size: 1.3rem;
  }
}

.uncategorized-description-text {
  font-size: 0.96rem;
  text-align: left;
}

.transactions-card-header-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transactions-header-text {
  font-weight: 550;
}

.uncategorized-transactions-card-container {
  width: 80%;
  margin: 0 auto;
  align-items: center;
  overflow-y: auto;
  max-height: 55vh;
}

.uncategorized-transactions-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid #eee;
  margin-top: 0.8rem;

  .transaction-name,
  .transaction-suggested-category {
    text-align: left;
    font-weight: 450;
  }

  .uncategorized-right-column {
    display: flex;
    align-items: center;
  }
  .transaction-est-budget {
    margin-right: 0.5rem;
    font-weight: 550;
  }
}

.uncategorized-button-container {
  width: 45%;
  height: 2.8rem;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5156be;
  color: white;
}

.pi-angle-right {
  cursor: pointer;
}
</style>
