<template>
  <div class="monetization-wrapper">
    <div class="monetization-container">
      <i
        class="pi pi-times"
        @click="$store.dispatch('toggle_monetization_modal')"
      ></i>
      <div>
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 1.33594L28.5 6.66927V14.6693C28.5 22.0693 23.38 28.9893 16.5 30.6693C9.62 28.9893 4.5 22.0693 4.5 14.6693V6.66927L16.5 1.33594ZM21.8333 18.6693H11.1667V20.6693C11.1667 21.0293 11.42 21.2826 11.7933 21.3359H21.0733C21.4867 21.3359 21.7667 21.1226 21.8333 20.7893V20.6693V18.6693ZM23.1667 10.6693L19.6067 14.2293L16.5 11.1226L13.3933 14.2293L9.83333 10.6693L11.1667 17.3359H21.8333L23.1667 10.6693Z" fill="#F4B50D"/>
        <mask id="mask0_25686_150386" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="1" width="25" height="30">
        <path d="M16.5039 1.33594L28.5039 6.66927V14.6693C28.5039 22.0693 23.3839 28.9893 16.5039 30.6693C9.62391 28.9893 4.50391 22.0693 4.50391 14.6693V6.66927L16.5039 1.33594ZM21.8372 18.6693H11.1706V20.6693C11.1706 21.0293 11.4239 21.2826 11.7972 21.3359H21.0772C21.4906 21.3359 21.7706 21.1226 21.8372 20.7893V20.6693V18.6693ZM23.1706 10.6693L19.6106 14.2293L16.5039 11.1226L13.3972 14.2293L9.83724 10.6693L11.1706 17.3359H21.8372L23.1706 10.6693Z" fill="#F4B50D"/>
        </mask>
        <g mask="url(#mask0_25686_150386)">
        <g filter="url(#filter0_f_25686_150386)">
        <rect x="26.9688" y="1.63281" width="9.60167" height="34.9264" transform="rotate(52.6326 26.9688 1.63281)" fill="#F8DF9E"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_f_25686_150386" x="-6.62906" y="-4.20719" width="45.265" height="40.5081" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2.92" result="effect1_foregroundBlur_25686_150386"/>
        </filter>
        </defs>
      </svg>
      </div>
      <div class="monetization-header">BUDGETWISE PREMIUM</div>
      <div class="monetization-sub-header">Upgrade to connect unlimited bank accounts!</div>

      <div class="monetization-card" :class="{ 'active': isActiveAnnually }" @click="toggleActiveAnnually">
        <div class="card-left">
          <div class="billed-text">Annually</div>
          <div class="billed-every-text">$3.99/mo. Billed Annually</div>
        </div>
        <div class="card-right amount">
          $47.99 / year
        </div>
      </div>

      <div class="monetization-card" :class="{ 'active': isActiveMonthly }" @click="toggleActiveMonthly">
        <div class="card-left">
          <div class="billed-text">Monthly</div>
          <div class="billed-every-text">$72/yr. Billed Monthly</div>
        </div>
        <div class="card-right amount">
          $5.99 / month
        </div>
      </div>

      <button class="continue-button" @click="handleContinueClick">Continue</button>

      <div class="info-text">
        Your subscription renews automatically until you turn it off. You
        can cancel your subscription anytime by managing your
        subscriptions in your account settings. By subscribing, you
        agree to our Terms of Service and acknowledge our Privacy
        Policy.
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";


export default defineComponent({
  name: "MonetizationPopup",
  data(){
    return {
      isActiveAnnually: false,
      isActiveMonthly: false,
    }
  },    
   //  window.open('https://buy.stripe.com/test_9AQ3f36WBgrQ5kkeUU', '_blank');
  methods: {  
    async handleContinueClick(){
      if(this.isActiveMonthly) {
        // this.$store.dispatch("toggle_payment_modal")
        window.location.href = 'https://buy.stripe.com/test_9AQ3f36WBgrQ5kkeUU';
      }
    },
    toggleActiveAnnually(){
      if(this.isActiveMonthly){
        this.isActiveMonthly = false
        this.$store.state.selected_package = "Monthly"
      }

      this.isActiveAnnually = !this.isActiveAnnually
    },
    toggleActiveMonthly(){
      if(this.isActiveAnnually){
        this.isActiveAnnually = false
      }

      this.isActiveMonthly = !this.isActiveMonthly
    },

  }
});
</script>

<style scoped>
.monetization-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.monetization-container {
  position: absolute;
  top: 10%;
  background-image: linear-gradient(#1F1F20 33%, #4A70AD 66%);
  border-radius: 15px;
  width: 35%;
  padding: 20px;
}

i {
    cursor: pointer;
}

.pi-times {
  display: flex;
  justify-content: right;
  color: white;
}

.monetization-header {
  margin-top: .5rem;
  background: linear-gradient(to right, #FEB93E 40%, #FED86C 50%, #FEB93E 60%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 500;
}

.monetization-sub-header{
  color: white;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.monetization-card {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin:auto;
  border: 2px solid white;
  color: #FFFEFE;
  padding: 8px;
  margin-top: 1rem;
  border-radius: 8px;
}

.billed-text {
  font-weight: 550;
  margin-bottom: 5px;
}

.billed-every-text {
  font-size: .9rem;
}

.amount {
  font-size: 1.4rem;
  font-weight: 500;
}

.card-left {
  text-align: left;
}

.monetization-card:hover, .monetization-card.active {
  background-image: linear-gradient(to right, #FFBA09, #F6D886);
  color: #2B2A2B;
}

.continue-button {
  background-color: #FFFEFE;
  width: 70%;
  margin: auto;
  text-align: center;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 9px 0;
  color: #4D82D8;
  font-weight: 450;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.info-text {
  width: 70%;
  margin: auto;
  color: white;
  text-align: left;
}

</style>
