<template>
<!-- register form -->
<div class="content">
    <v-img width="650" height="650" style=" align-self: flex-start; display: flex; margin-top: 120px;" src="../assets/login-image.png"></v-img>
      <div class="login-box noselect">
        <div class="login-wrap">
            <v-img width="55" height="55" style="border-radius: 9px;" src="../assets/NewLogoLight.png"></v-img>
          <v-img width="151" height="27" style="transform: translateY(5px); margin-bottom: 1rem;" src="../assets/budetwise.png"></v-img>
      <div class="header">
          <h5>Sign up with email</h5>
        </div>
        <form @submit.prevent="submitRegisterForm">
          <div class="login-field">
            <input v-model="email" autocomplete="off" placeholder=" Email" />
          </div>

          <div class="login-field">
            <input
              v-model="password"
              placeholder=" Password"
              type="password"
              autocomplete="off"
            />
          </div>
          <div class="error" v-if="error_msg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                d="M12.2526 4.67599L2.77883 21.0844C2.70346 21.2158 2.66389 21.3646 2.66406 21.5161C2.66424 21.6675 2.70415 21.8162 2.77981 21.9474C2.85548 22.0786 2.96425 22.1876 3.09525 22.2636C3.22625 22.3396 3.37489 22.3798 3.52633 22.3804H22.4738C22.6253 22.3798 22.7739 22.3396 22.9049 22.2636C23.0359 22.1876 23.1447 22.0786 23.2203 21.9474C23.296 21.8162 23.3359 21.6675 23.3361 21.5161C23.3363 21.3646 23.2967 21.2158 23.2213 21.0844L13.7476 4.67599C13.6714 4.54533 13.5623 4.43692 13.4311 4.36158C13.3 4.28623 13.1513 4.24658 13.0001 4.24658C12.8488 4.24658 12.7002 4.28623 12.569 4.36158C12.4379 4.43692 12.3288 4.54533 12.2526 4.67599Z"
                fill="#EFCC00"
              />
              <path
                d="M11.7813 18.6876C11.7813 18.4465 11.8528 18.2109 11.9867 18.0105C12.1206 17.81 12.311 17.6538 12.5337 17.5616C12.7564 17.4693 13.0014 17.4452 13.2379 17.4922C13.4743 17.5393 13.6914 17.6553 13.8619 17.8258C14.0323 17.9962 14.1484 18.2134 14.1954 18.4498C14.2424 18.6862 14.2183 18.9313 14.1261 19.154C14.0338 19.3767 13.8776 19.567 13.6772 19.7009C13.4768 19.8348 13.2411 19.9063 13.0001 19.9063C12.8382 19.9132 12.6766 19.8864 12.5257 19.8276C12.3747 19.7688 12.2375 19.6793 12.123 19.5647C12.0084 19.4501 11.9189 19.313 11.86 19.162C11.8012 19.011 11.7744 18.8494 11.7813 18.6876ZM12.2241 16.7944L11.9154 10.7007H14.0523L13.7557 16.7944H12.2241Z"
                fill="#353535"
              />
            </svg>
            {{ error_msg }}
          </div>
          <v-row justify="start">
                <v-col cols="12" class="text-left">
                    <v-sheet style="font-family: Outfit; font-weight: 400; margin-top: 5px;">
                        <p style="color:#707070; font-size:14px; transform: translateX(8px);">Use at least 8 characters.</p>
                    </v-sheet>
                </v-col>
            </v-row>
          <button
            class="login-button"
            :style="{
              background: isEnabled ? '#5157BF' : '#c9ccec',
            }"
            type="submit"
            @click="displayError"
          >
            SIGN UP
          </button>
          <v-row justify="start">
                <v-col cols="12">
                    <v-sheet style="font-family: Outfit; font-weight: 300; margin-top: 0px;">
                        <p style="color:#707070; font-size:14px;">Already have an account? <span @click="$router.push('/email')" style="color: var(--Primary400, #5157BF); text-decoration-line: underline; cursor: pointer;">Log in using email.</span>
                        </p>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row justify="start">
                <v-col cols="12">
                    <v-sheet style="font-family: Outfit; font-weight: 300; transform: translateY(-20px);">
                        <p style="color:#707070; font-size:14px;">or</p>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row justify="start">
                <v-col cols="12">
                    <v-sheet style="font-family: Outfit; font-weight: 300; transform: translateY(-40px);">
                        <p style="color:#707070; font-size:14px;"><span @click="$router.push('/login')" style="color: var(--Primary400, #5157BF); text-decoration-line: underline; cursor: pointer;">Go back to log in page.</span></p>
                    </v-sheet>
                </v-col>
            </v-row>
        </form>
      </div>
      </div>
    </div>
        <!-- register form -->
</template>

<script>
import { auth } from "../main";
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";

import {
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";

export default {
  name: "LoginBox",
  data() {
    return {
      email: "",
      password: "",
      stored_auth_code: "",
      error_msg: "",
      loader_style: "hidden",
    };
  },
  methods: {
   
    /**
     * Displays a given error.
     */
    displayError(err) {
      this.error_msg = err;
      setTimeout(
        function () {
          this.error_msg = "";
        }.bind(this),
        3000
      );
    },
    
    /**
     * Validates user credentials and redirects to /home on success.
     * Displays an error otherwise*/
    async loginWithEmailAndPass() {
      if (this.password == "") {
        this.error_msg = " The password cannot be empty";
      } else {
        try {
          this.loader_style = "visible";
          await signInWithEmailAndPassword(auth, this.email, this.password)
            .then(() => {
              // Signed in
              this.$router.push("/home");
            })
            .catch((error) => {
              let temp_err;
              /* const errorCode = error.code; */
              const errorMessage = error.message;

              console.log(errorMessage);
              switch (error.message) {
                case "Firebase: Error (auth/invalid-email).":
                  temp_err = "Incorrect email or password.";
                  break;
                case "Firebase: Error (auth/wrong-password).":
                  temp_err = "Incorrect email or password.";
                  break;
                case "Firebase: Error (auth/user-not-found).":
                  temp_err = "User with this email doesn't exist.";
                  break;
                case "Firebase: Error (auth/user-disabled).":
                  temp_err = "User with this email has been disabled.";
                  break;
                case "Firebase: Error (auth/too-many-requests).":
                  temp_err = "Too many requests. Try again later.";
                  break;
                case "Firebase: Error (auth/operation-not-allowed).":
                  temp_err =
                    "Signing in with Email and Password is not enabled.";
                  break;
                case "Firebase: Error (auth/network-request-failed).":
                  temp_err = "It looks like you are offline. Try again later.";
                  break;
                default:
                  temp_err = "Something went terribly wrong.";
              }
              this.displayError(temp_err);
              this.loader_style = "hidden";
            });
        } catch (error) {
          console.log(error);
        }
      }
    },

    /**
     * Validates all fields and creates a user with a given name and password.
     */
     async submitRegisterForm() {
  try {
    await createUserWithEmailAndPassword(auth, this.email, this.password);
    sendEmailVerification(auth.currentUser)
      .then(() => {
        // Email verification has been sent.
      });

    this.$router.push("/home");
  } catch (error) {
    let temp_err;
    console.log(error);

    switch (error.message) {
      case "Firebase: Error (auth/email-already-in-use).":
        temp_err =
          'This email is already registered. If you forgot your password click on "Forgot Password?"';
        break;
      case "Firebase: Error (auth/invalid-email).":
        temp_err = "Invalid email.";
        break;
      case "Firebase: Error (auth/operation-not-allowed).":
        temp_err = "Email/Password sign-in is currently disabled.";
        break;
      case "Firebase: Error (auth/weak-password).":
        temp_err = "Your password is too weak.";
        break;
      case "Firebase: Error (auth/network-request-failed).":
        temp_err = "It looks like you are offline. Try again later.";
        break;
      default:
        temp_err = "Something went terribly wrong.";
    }
    this.displayError(temp_err);
  }
}

  },
  computed: {
    loginBtnColor() {
      if (this.resetPage) {
        return this.email && this.email.length > 0 ? "#c9ccec" : "#5157BF";
      } else if (this.registerPage) {
        return "#c9ccec";
      } else {
        return "#c9ccec";
      }
    },
    isEnabled() {
      if (this.resetPage) {
        return this.email;
      } else if (this.registerPage) {
        return (
          this.email &&
          this.password
        );
      } else {
        return this.email && this.password;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700&family=Outfit:wght@600&display=swap");
.content {
  width: 100%;
  height: 100%;
  display: flex;
 
  font-family: "Outfit";
  font-style: normal;
  justify-content: center;
  background: var(--Primary50, #F9FAFF);
}

.error {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  /* or 120% */

  letter-spacing: -0.5px;

  color: #000000;
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    width: 50px;
  }
}
#align-left {
  text-align: left;
  padding: 0;
}

.login-box {
  max-width: 450px;
  width: 100%;
  background: var(--Base0, #FFF);
  border: 1px solid var(--Base40, #DDD);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2.5rem 10px;
  align-items: center;
  margin-right: 300px;
  align-self: center;
  
}
.header {
    margin-bottom: 10px;
  width: 83%;
  h5 {
    font-family: "Outfit";
    font-style: normal;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    /* or 29px */


    /* Base100 */

    color: #2b2b2b;
    margin: 0;
    padding: 0px 0;
    span {
      color: #6970c9;
      font-weight: 500;
    }
  }
  #text {
    width: 80%;

    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    color: #000000;
    text-align: left;
  }
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* identical to box height, or 18px */

  color: #000000;
  margin-top: 2rem;
  gap: 10px;

  svg {
    width: 30%;
  }
}

.login-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;

  form {
    width: 85%;
  }
}

.login-field {
  margin-top: 2%;
  position: relative;
  font-weight: 100;
  font-family: "Outfit";
}

.login-field input {
  border: 1px solid #a0a0a0;

  border-radius: 10px;
  text-align: left;
  text-indent: 1em;
  width: calc(100% - 10px);

  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  height: 60px;

  color: #707070;
}

.login-field input:focus,
.login-field input:active {
  outline: none;
}

.login-field input::placeholder,
.login-field input::placeholder {
  font-weight: 300;
  font-size: 15px;

  align-content: center;
}

.login-button {
  width: 100%;
  margin: 10px 0;
  margin-top: 24px;
  font-weight: 500;
  padding: 15px 32px;
  text-transform: uppercase;
  border: none;
  letter-spacing: 1px;
  cursor: pointer;

  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* or 18px */

  text-align: center;

  color: #ffffff;
  border-radius: 8px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-password {
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  padding: 6px 0;
  /* color: var(--base-color-400); */
  cursor: pointer;
  text-decoration: underline;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* or 120% */

  letter-spacing: -0.5px;
  text-decoration-line: underline;

  color: #000000;
}

.signup-btn {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  color: #000000;

  cursor: pointer;

  span {
    text-decoration: underline;
  }
}
.signup-btn a {
  text-decoration: none;
  color: var(--base-color-400);
  cursor: pointer;
}

/*
Loader
*/
.loader-box {
  margin-top: none;
  padding-top: 40px;
  padding-left: 4px;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: none;
}

.loader-element {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-element div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 8px;
  background: rgb(208, 100, 230);
  -webkit-animation: loader-element 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation: loader-element 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  border-radius: 10px;
}
.loader-element div:nth-child(1) {
  left: 8px;
  -webkit-animation-delay: -0.24s;
  animation-delay: -0.24s;
}
.loader-element div:nth-child(2) {
  left: 32px;
  -webkit-animation-delay: -0.12s;
  animation-delay: -0.12s;
}
.loader-element div:nth-child(3) {
  left: 56px;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
@-webkit-keyframes loader-element {
  0% {
    top: 16px;
    height: 8px;
  }
  50% {
    top: 32px;
    height: 16px;
  }
  100% {
    top: 8px;
    height: 16px;
  }
}
@keyframes loader-element {
  0% {
    top: 16px;
    height: 8px;
  }
  50% {
    top: 32px;
    height: 16px;
  }
  100% {
    top: 8px;
    height: 16px;
  }
}

/*
End of Loader2
*/

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Mid screens */
@media only screen and (max-width: 1100px) {
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .content {
    background: #ffffff;
  }
  .login-box {
    width: 100%;
  }
  .login-wrap {
    width: 100%;
  }

  .login-field input {
    width: 100%;
  }

  .login-box {
    margin: 1rem 4rem;
    width: 80%;
    overflow-y: scroll;
  }
  .login-field input,
  .login-button {
    height: 40px;
  }
  
  .divider {
    gap: 5px;
  }
  .provider {
    padding: 6px 15px;
    font-size: 13px;
  }
}
</style>