<template>
  <div class="center-container">
    <div class="trans-content">
      <div class="trans-container">
        <router-link to="/BudgetView">
          <i class="pi pi-arrow-left arrow-icon"></i>
        </router-link>
        <p style="display: inline-block; font-weight: bold; font-size: 20px;">Create a Budget</p>
      </div>
      <div class="name-budget-content">
        <div class="progress">
            <div class="progress-point" id="green"></div>
            <div class="progress-point" id="green"></div>
            <div class="progress-point" id="green"></div>
        </div>
        <p style="font-weight: bold; font-size: 18px">Last step! Name your budget</p>
        <input type="text" v-model="fullName" class="custom-input" />
        <div class="trans-buttons">
          <router-link to="/BudgetView">
            <button class="button-style">BACK</button>
          </router-link>
          <!-- <router-link to="/"> -->
            <button class="button-style" @click="logFullName">FINISH CREATING BUDGET</button>
          <!-- </router-link>   -->
        </div>
      </div>
    </div>
   </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { auth } from "@/main";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; // Import useRouter from vue-router
import PlaidService from "../services/PlaidService";

const store = useStore();
const router = useRouter(); // Initialize router

const fullName = ref(""); 

onMounted(() => {
  fullName.value = `${store.state.user.first_name}'s Monthly Budget`;

});

const logFullName = async () => {
  console.log(fullName.value); // Log the fullName value
  const budget = { ...store.state.selected_budget }; // Copy the selected_budget object
  budget.name = fullName.value; // Update the name property
  console.log('Updated Budget:', budget); // Log the updated Budget object

  // CREATING BUDGET TO GET budgetId
  const createBudgetObj = {
    name: fullName.value,
    amount: budget.averageExpenses
  }

  console.log('createBudgetObj', createBudgetObj);

  const userId = auth.currentUser && auth.currentUser.uid;

  const budgetId = await PlaidService.createBudget(userId, createBudgetObj);

  console.log('budgetId', budgetId);

  // CREATING ANY NEW CATEGORIES TO USERS BUDGET

  const holdingAllExpenseDetails = store.state.holdingAllExpenseDetails;

  const expenseCategories = holdingAllExpenseDetails.expenses;

  for (const [key, expense] of Object.entries(expenseCategories)) {
    if (expense === undefined) continue; // Skip explicitly undefined objects

    const newCategoryName = expense.name || expense.category;

    let amount = 0;
    if (expense.amount && typeof expense.amount === 'string') {
      amount = parseFloat(expense.amount.replace('$', '')) || 0; // Remove $ sign and convert to double
    }

    const newCategory = {
      budgetId: budgetId,
      name: newCategoryName,
      amount: amount,
      category_type: expense.category,
      expense_type: expense.type,
      image: ""
    };

    console.log('newCategory', newCategory);

    await PlaidService.createCategory(userId, newCategory);
  }

  // Trigger deleteTransaction when updating budget name
  const deleteDetails = store.state.deleteExpenseDetails;
  const categoriesToDelete = Object.keys(deleteDetails.categories);



  for (let category of categoriesToDelete) {
    const transactionIds = deleteDetails.categories[category];
    for (let transactionId of transactionIds) {
      let object = {
        userId: deleteDetails.user_id,
        transactionId,
        category_type: category,
      }

      console.log('delete this object:', object);

      await PlaidService.deleteTransaction(object);
    }
  }

  store.dispatch('setBudgetName', budget);

  router.push("/home");
};
</script>

<style scoped>
.custom-input {
  margin-top: 1rem;
  border: 1px solid black;
  border-radius: 2%;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
}

p {
  display: flex;
  justify-content: flex-start;
}
.center-container {
  display: flex;
  justify-content: center;
  width:90%;
}

.trans-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}

.trans-content {
  height: 100vh;
  width: 65%;
  max-width: 600px;
  max-height: 400px;
}

.name-budget-content {
  height: 100vh;
  width: 60%;
  max-width: 600px;
  max-height: 400px;
  margin-left: 100px
}

.arrow-icon {
  margin-right: 18px;
  vertical-align: middle;
  cursor: pointer;
  color: black !important;

}

.trans-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.button-style {
  padding: 5px 20px;
  background-color: #5157BF;
  color: white;
  border-radius: 10px;
}

.progress {
    display: flex;
    gap: 10px;
    margin-bottom: 25px;
    margin-top: 25px;
}

.progress-point {
    width: 35px;
    height: 8px;
    background: #f3f3f3;
    border-radius: 8px;
}
#green {
    background-color: #53bd71;
}

</style>
